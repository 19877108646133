// CSS Variable
:root {
    --font-family-1: 'Open Sans', arial, helvetica, sans-serif;
    --font-family-2: 'Open Sans', arial, helvetica, sans-serif;
    --fontsize-base: 18px;

    --grey: 123, 123, 123;
    --grey-ultralight: 241, 241, 241;
    --grey-mediumlight: 224, 224, 224;
    --grey-light: 153, 153, 153;

    --font-color: 78, 78, 78;
    --paragraph: 112, 112, 112;

    --textlink: 255, 0, 0;
    --textlinkHover: 0, 255, 0;

    --bodyBG: 255, 255, 255;
    --footerBG: 51, 49, 49;
    --bg: 193, 191, 191;
    --headlineBorder: 152, 152, 152;

    --color-1: 0, 168, 235;
    --color-2: 0, 184, 167;
    --color-3: 32, 106, 99;
    --color-4: 78, 108, 117;

    --sectionBG-1: 241, 241, 241;
    --sectionBG-2: 245, 245, 245;
    --sectionBG-3: 78, 108, 117;
    --sectionBG-4: 0, 184, 167;

    --bs-tabs: 155, 155, 155;
    --bs-tabs-active: 51, 49, 49;
}
$fontsize-base: var(--fontsize-base);
$grey: rgba(var(--grey), 1);
$grey-ultralight:rgba(var(--grey-ultralight), 1);
$grey-mediumlight:rgba(var(--grey-mediumlight), 1);
$grey-light:rgba(var(--grey-light), 1);

$font-color: rgba(var(--font-color), 1);
$paragraph: rgba(var(--paragraph), 1);

$textlink: rgba(var(--textlink), 1);
$textlinkHover: rgba(var(--textlinkHover), 1);

$bodyBG: rgba(var(--bodyBG), 1);
$footerBG: rgba(var(--footerBG), 1);
$bg: rgba(var(--bg), 1);
$headlineBorder: rgba(var(--headlineBorder), 1);

$color-1: rgba(var(--color-1), 1);
$color-2: rgba(var(--color-2), 1);
$color-3: rgba(var(--color-3), 1);
$color-4: rgba(var(--color-4), 1);

$sectionBG-1: rgba(var(--sectionBG-1), 1);
$sectionBG-2: rgba(var(--sectionBG-2), 1);
$sectionBG-3: rgba(var(--sectionBG-3), 1);
$sectionBG-4: rgba(var(--sectionBG-4), 1);

$bs-tabs:  rgba(var(--bs-tabs), 1);
$bs-tabs-active:  rgba(var(--bs-tabs-active), 1);

$font-family-1: var(--font-family-1);
$font-family-2: var(--font-family-2);

// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs: 480px !default;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min: $screen-xs !default;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone: $screen-xs-min !default;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm: 768px !default;
$screen-sm-min: $screen-sm !default;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet: $screen-sm-min !default;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md: 1000px !default;
$screen-md-min: $screen-md !default;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop: $screen-md-min !default;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg: 1340px !default;
$screen-lg-min: $screen-lg !default;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop: $screen-lg-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm-min - 1) !default;
$screen-sm-max: ($screen-md-min - 1) !default;
$screen-md-max: ($screen-lg-min - 1) !default;
// BS GRID
$grid-columns: 24 !default;
//** Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width: 50px !default;

//Container sizes
$container-tablet: (750px) !default;
$container-sm: $container-tablet !default;
$container-desktop: (972px) !default;
$container-md: $container-desktop !default;
$container-large-desktop: (1270px + $grid-gutter-width) !default;
$container-lg: $container-large-desktop !default;

$screen-xs: 480px !default;
$screen-sm: 768px !default;
$screen-md: 1000px !default;
$screen-lg: 1290px !default;

$grid-float-breakpoint: $screen-md-min !default;
//** Point at which the navbar begins collapsing.
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1) !default;

// Basics of a navbar
$navbar-height: auto !default;
$navbar-collapse-max-height: 100vh !default;

$navbar-default-color: $grey-light !default;
$navbar-default-bg: #fff !default;

// Navbar links
$navbar-default-link-color: $grey-light !default;
$navbar-default-link-hover-color: $color-1 !default;
$navbar-default-link-hover-bg: transparent !default;
$navbar-default-link-active-color: $color-1 !default;
$navbar-default-link-active-bg: #fff !default;
$navbar-default-link-disabled-color: #ccc !default;
$navbar-default-link-disabled-bg: transparent !default;

// Navbar brand label
$navbar-default-brand-color: $color-1 !default;
$navbar-default-brand-hover-color: $color-1 !default;
$navbar-default-brand-hover-bg: transparent !default;

// Navbar toggle
$navbar-default-toggle-hover-bg: #fff !default;
$navbar-default-toggle-icon-bar-bg: #000 !default;
$navbar-default-toggle-border-color: transparent !default;

$font-family-sans-serif: 'Open Sans', arial, helvetica, sans-serif;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace: Menlo, Monaco, Consolas, "Courier New", monospace !default;
$font-family-base: $font-family-sans-serif !default;

$font-size-base: 10px !default;
$font-size-large: ceil(($font-size-base * 1.25)) !default; // ~18px
$font-size-small: ceil(($font-size-base * 0.85)) !default; // ~12px

$font-size-h1: 48px !default;
$font-size-h2: 27px !default;
$font-size-h3: ceil(($font-size-base * 1.7)) !default; // ~24px
$font-size-h4: ceil(($font-size-base * 1.25)) !default; // ~18px
$font-size-h5: $font-size-base !default;
$font-size-h6: ceil(($font-size-base * 0.85)) !default; // ~12px

//** Unit-less `line-height` for use in components like buttons.
$line-height-base: 1.428571429 !default; // 20/14



//TRANSITIONS===================//
$t-type:cubic-bezier(0.785, 0.135, 0.150, 0.860);
$t-fast:all $t-type 0.25s;
$t-medium:all $t-type 0.4s;
$t-slow:all $t-type 0.8s;

