/*Testimonial*/

section.testimonial {
  position: relative;
  background: url("../Img/tools/heroIMG.jpg") no-repeat center center;
  background-size: cover;
  text-align: center;
  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    background: rgba(var(--color-4), .6);
  }
  .item {
    position: relative;
    z-index: 2;
    text-align: left;
    display: inline-block;
    width: 75%;
    figure {
      width: 25%;
      max-width: 200px;
      overflow: hidden;
      border-radius: 200px;
      margin-right: 25px;
      display: inline-block;
      vertical-align: middle;
      img {

      }
    }
    blockquote {
      width: calc(75% - 30px);
      display: inline-block;
      vertical-align: middle;
      font-weight: 600;
      font-size: 24px;
      color: #FFFFFF;
      letter-spacing: -0.34px;
      line-height: 33px;
    }
  }
}

/*==========  BREAKPOINTS  MAX-WIDTH ==========*/

@media only screen and (max-width: 1339px) {
  section.testimonial {
    .item {
      width: 100%;
      blockquote {
      }
      figure {
      }
    }
  }
}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/

@media only screen and (max-width: 999px) {

}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/

@media only screen and (max-width: 767px) {
  section.testimonial {
    .item {
      width: 100%;
      blockquote {
        font-size: $fontsize-base;
        color: #FFFFFF;
        letter-spacing: -0.26px;
        line-height: 24px;
      }
    }
  }
}

/*END BREAKPOINT MAX 767px-------------------------------------------------*/

@media only screen and (max-width: 580px) {
  section.testimonial {
    .item {
      width: 100%;
      text-align: center;
      figure {
        width: 50%;
        max-width: 250px;
        margin-right: 0;
        margin-bottom: 25px;
      }
      blockquote {
        text-align: left;
        width: 100%;
      }
    }
  }
}

/*END BREAKPOINT MAX 479px-------------------------------------------------*/

@media only screen and (max-width: 375px) {
  section.testimonial {
    .item {
      figure {
        width: 250px;
      }
    }
  }
}

/*END BREAKPOINT MAX 374px-------------------------------------------------*/

@media only screen and (max-width: 320px) {

}

/*END BREAKPOINT MAX 320px-------------------------------------------------*/

/*==========  BREAKPOINTS  MIN-WIDTH ==========*/

@media only screen and (min-width: 320px) {

}

/*END BREAKPOINT MIN 320px-------------------------------------------------*/

@media only screen and (min-width: 375px) {

}

/*END BREAKPOINT MIN 375px-------------------------------------------------*/

@media only screen and (min-width: 480px) {

}

/*END BREAKPOINT MIN 480px-------------------------------------------------*/

@media only screen and (min-width: 768px) {

}

/*END BREAKPOINT MIN 768px-------------------------------------------------*/

@media only screen and (min-width: 1000px) {

}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/

@media only screen and (min-width: $screen-lg) {

}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/