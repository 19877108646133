.tools2 {
  h1, .h1 {
    font-weight: 500;
    color: $color-3;
  }
  p {
    font-weight: 500;
    font-size: 24px;
    line-height: 33px;
    letter-spacing: -0.34px;
  }
  &.banken {
    padding: 150px 0;
    .info{
      float: right;
    }
    img {
      transform-origin: right center;
      transform: scale(1.5);
    }
  }
  &.team{
    .info{
      transform: translateY(50%);
      margin-top: -25px;
    }
    .image{
      text-align: center;
      img{
        max-width: 430px;
        display: inline-block;
      }
    }
  }
}

/*==========  BREAKPOINTS  MAX-WIDTH ==========*/

@media only screen and (max-width: 1339px) {
  .tools2{
    &.banken{
      padding: 120px 0;
      img {
        transform-origin: right center;
        transform: scale(1.85) translateY(31px);
      }
    }
    &.team {
      .info {
        transform: translateY(0);
        margin-top: 0;
      }
    }
  }
}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/

@media only screen and (max-width: 999px) {
  .tools2 {
    h1, .h1 {
      font-size: 24px;
      line-height: 33px;
      letter-spacing: -0.34px;
    }
    p{
      font-size: $fontsize-base;
      line-height: 24px;
      letter-spacing: -0.26px;
    }
  }
}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/

@media only screen and (max-width: 767px) {
  .tools2 {
    h1, .h1 {
    }
    p{
    }
    .image, .info{
      float: none;
    }
    .info{
      margin-bottom: 51px;
    }
    &.banken{
      padding: 51px 0;
      img{
        transform: none;
      }
    }
    &.team{
      .info{
        transform: none;
        margin-top: 0;
      }
      .image{

      }
    }
  }
}

/*END BREAKPOINT MAX 767px-------------------------------------------------*/

@media only screen and (max-width: 479px) {
  .tools2 {
    h1, .h1 {
    }
    p{
    }
    .image, .info{
    }
    .info{
      margin-bottom: 25px;
    }
    &.banken{
      img{

      }
    }
  }
}

/*END BREAKPOINT MAX 479px-------------------------------------------------*/

@media only screen and (max-width: 374px) {

}

/*END BREAKPOINT MAX 374px-------------------------------------------------*/

@media only screen and (max-width: 320px) {

}

/*END BREAKPOINT MAX 320px-------------------------------------------------*/

