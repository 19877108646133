
.ce-faq{
  .container{
    max-width: 1000px;
  }
}
.collapse {
  display: none;
  &.show, &.in {
    display: block;
  }
}
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition-property: height,visibility;
  transition-duration: .35s;
}
.faq_question{
  color: $grey;
  font-size: 1.17em;
  line-height: 1;
  cursor:pointer;
  margin: 0 0 20px 0;
  padding-left: 34px;
  position: relative;
 .toggler{
    position: absolute;
    left: 0;
    width: 20px;
    height: 20px;
    line-height: 20px;
    font-size: 16px;
    font-weight: normal;
    transform: translateY(-2px);
    text-align: center;
    display: inline-block;
    background: rgba(var(--grey), .13);
    border-radius: 50%;
    margin-right: 10px;
    transition: background-color .3s;
    color: $grey;
   &:before, &:after{
     content: "";
     position: absolute;
     display: block;
     left: 50%;
     top: 50%;
     transform: translate(-50%, -50%);
     width: 8px;
     height: 2px;
     background: currentColor;
     transition: all .25s;
   }
   &:after{
     transform: translate(-50%, -50%) rotate(90deg);
   }
  }

  &:hover {
    .toggler{
      color: #fff;
      background: $color-2;
    }
  }
  &[aria-expanded="true"]{
    .toggler{
      color: #fff;
      background: $color-2;
      &:after{
        transform: translate(-50%, -50%) rotate(90deg) scaleY(0);
      }
    }
  }
}
.faq_answer{
  color: $grey;
  padding: 0 0 23px 34px;
  ul + p, ol + p{
    padding-top: 20px;
  }
}
