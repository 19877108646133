/*VORLAGE*/
section.secuityKeyfeatures {
  padding: 0;
  overflow: hidden;
  .container {
    width: 100%;
  }
  .item {
    position: relative;
    display: table;
    .inner {
      padding: 20px 0;
      display: table-cell;
      vertical-align: middle;
      .content {
        max-width: 610px;
      }
    }
    .imageHead {
      margin-bottom: 25px;
      img, h2, .h2 {
        display: inline-block;
        vertical-align: middle;
      }
      img {
        width: 33.33333%;
        max-width: 180px;
        margin-right: 20px;
      }
      h2, .h2 {
        max-width: calc(66.66666% - 25px);
        display: none;
      }
    }
    h2, .h2 {
      font-size: 28px;
      letter-spacing: -0.4px;
      line-height: 32px;
      font-weight: 400;
      color: $color-2;

    }
    h3, .h3 {
      font-size: 24px;
      line-height: 33px;
      font-weight: 600;
      color: $color-3;
      letter-spacing: -0.34px;
      margin-bottom: 10px;
    }
    &.bgGreen {
      h2, .h2, h3, .h3 {
        color: #fff;
      }
      &:before {
        content: "";
        display: block;
        background: $color-2;
        width: 200px;
        height: 100%;
        position: absolute;
        right: 0;
        transform: translateX(100%);

      }
      + .bgGreen {
        &:before {
          left: 0;
          right: auto;
          transform: translateX(-100%);
        }
      }
    }
    &:nth-child(odd) {
      .inner {
        .content {
          float: right;
        }
      }
    }
  }
  //END .item
}

/*==========  BREAKPOINTS  MAX-WIDTH ==========*/

@media only screen and (max-width: 1339px) {
  section.secuityKeyfeatures {
    .container {
      width: 100%;
    }
  }
}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/

@media only screen and (max-width: 999px) {
  section.secuityKeyfeatures {
    .item {
      h2, .h2 {
        font-size: 20px;
        line-height: 24px;
        text-align: center;
      }
      h3, .h3 {
        font-size: $fontsize-base;
        line-height: 24px;
      }
    }
  }
}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/

@media only screen and (max-width: 767px) {
  section.secuityKeyfeatures {
    .container {
      .visible-xs {
        display: table !important;
      }
    }
  }
}

/*END BREAKPOINT MAX 767px-------------------------------------------------*/

@media only screen and (max-width: 479px) {

}

/*END BREAKPOINT MAX 479px-------------------------------------------------*/

@media only screen and (max-width: 374px) {

}

/*END BREAKPOINT MAX 374px-------------------------------------------------*/

@media only screen and (max-width: 320px) {

}

/*END BREAKPOINT MAX 320px-------------------------------------------------*/

/*==========  BREAKPOINTS  MIN-WIDTH ==========*/

@media only screen and (min-width: 320px) {

}

/*END BREAKPOINT MIN 320px-------------------------------------------------*/

@media only screen and (min-width: 375px) {

}

/*END BREAKPOINT MIN 375px-------------------------------------------------*/

@media only screen and (min-width: 480px) {

}

/*END BREAKPOINT MIN 480px-------------------------------------------------*/

@media only screen and (min-width: 768px) {

}

/*END BREAKPOINT MIN 768px-------------------------------------------------*/

@media only screen and (min-width: 1000px) {

}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/

@media only screen and (min-width: $screen-lg) {
  section.secuityKeyfeatures {
    .item {
      &:nth-child(even) {
        .inner {
          padding-left: 40px;
        }
      }
    }
  }
}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/