/*Plan with tools*/

/*==========  BREAKPOINTS  MAX-WIDTH ==========*/

.plan-with-tools {
  position: relative;
  overflow: hidden;
  h1, .h1, h2, .h2, h3, .h3, p, a {
    color: #fff;
  }
  .container {
    width: 100%;
    max-width:1320px;
  }
  .item {
    position: relative;
    z-index: 2;
    padding: 50px 0;
    &:after {
      content: "";
      display: block;
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      width: 1px;
      height: 120%;
      background: #fff;
    }
    .image {
      img {
        width: 100%;
        height: auto;
        position: relative;
        top: 50%;
      }
    }
    .info {
      text-align: left;
      .table {
        width: 100%;
        height: 100%;
        min-width: 0;
      }
    }
    .steps {
      text-align: center !important;
      vertical-align: middle !important;
      position: relative;
      .step {
        width: 44px;
        height: 44px;
        background: $color-2;
        position: absolute;
        z-index: 10;
        display: block;
        text-align: center;
        border: 7px solid #fff;
        border-radius: 22px;
        top: 50%;
        left: 50%;
        transform: translate3D(-50%, -50%, 0);
      }
    }
    &:nth-child(odd) {
      .image {
        img {
          transform: translate3D(12.5%, -50%, 0) scale(1.0);
        }
      }
    }
    &:nth-child(even) {
      .image {
        img {
          transform: translate3D(-12.5%, -50%, 0) scale(1.0);
        }
      }
    }
    &:nth-child(2) {
      &:after {
        height: 100%;
      }
    }
    &.double {
      margin-bottom: 125px;
      padding-top: 190px;
      &:after {
        top: 0;
        height: 220px;
      }
      .info {
        top: 100px;
        z-index: 10;
        h2 {
          margin-top: 30px;
        }

      }
      .steps {
        .line-horz {
          position: absolute;
          z-index: 1;
          height: 1px;
          width: 50vW;
          max-width: 640px;
          left: 50%;
          transform: translateX(-50%);
          top: 22px;
          background: #fff;
          &:before, &:after {
            display: block;
            width: 1px;
            height: 100px;
            background: #fff;
            position: absolute;
            top: 0;
            content: "";
          }
          &:before {
            left: 0;
          }
          &:after {
            right: 0;
          }
        }
        .step {
          top: 0;
          transform: translate3D(-50%, 0, 0);
          &:after {
            content: "";
            display: block;
            width: 4px;
            background: $color-2;
            height: 150px;
            top: calc(100% + 7px);
            position: absolute;
            left: 50%;
            z-index: 1;
            margin-left: -3px;
          }
        }
      }
    }
  }
  //END .item

}

@media only screen and (max-width: 1200px) {
  .plan-with-tools {
    .item:nth-child(odd) {
      .image {
        img {
          transform: translate3D(12.5%, -50%, 0) scale(1.25);
        }
      }
    }
    .item:nth-child(even) {
      .image {
        img {
          transform: translate3D(-12.5%, -50%, 0) scale(1.25);
        }
      }
    }
  }
}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/

@media only screen and (max-width: 999px) {

}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/

@media only screen and (max-width: 767px) {
  .plan-with-tools {
    padding-bottom: 0;
    margin-bottom: 60px;
    .container {
      .row.centered {
        .item {
          padding-top: 0 !important;
          top: 0 !important;
          [class*="col-"] {
            display: block;
            float: right;
            width: calc(100% - 50px);
            margin: 0 0 30px 0;
            .h2, h2 {
              margin-top: 0;
              font-size: 20px;
              letter-spacing: -0.28px;
              line-height: 27px;
              margin-bottom: 10px;
              position: relative;
              &:before{
                content: "";
                display: block;
                position: absolute;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                position: relative;
                top: 30px;
                left: -51px;
                z-index: 10;
                border:7px solid #fff;
                background: $color-2;
              }
            }
            p {
              font-size: 16px;
              letter-spacing: -0.23px;
              line-height: 22px;
              margin-bottom: 25px;
            }
            &.steps {
              width: 50px;
              float: left;
              padding-right: 0;
              .step {
                display: none;
                width: 30px;
                height: 30px;
                position: relative;
                top: 14px;
                left: 15px;
                &:before, &:after {
                  display: none !important;
                }
              }
            }
          }
          .image {
            img {
              position: static;
              transform: translate3D(0, 0, 0) scale(1) !important;
            }
          }
          &:after {
            left: 40px;
            top: 40px;
          }
          &.double {
            padding-top: 50px;
            padding-bottom: 0;
            margin-bottom: 0;
            h2, .h2{
              &:before{
                border-radius: 0;
                height: 1px;
                border: none;
                background: #fff;
                width: 25px;
                left: -35px;
                top: 15px;

              }
            }
            &:after {
              height: 100%;
            }
            .steps {
              .line-horz {
                display: none;
              }
            }
            .info {
              top: 0;
              //margin-bottom: 0;
              &:after{
                position: absolute;
                height: calc(100% + 16px);
                content: "";
                display: block;
                left: -30px;
                top:16px;
                width: 30px;
                background: $color-2;
              }
              &:first-child{
                &:after{
                  display: none;
                  //margin-bottom: 30px;
                }
              }
            }
          }
        }
      }
    }
  }
}

/*END BREAKPOINT MAX 767px-------------------------------------------------*/

@media only screen and (max-width: 479px) {

}

/*END BREAKPOINT MAX 479px-------------------------------------------------*/

@media only screen and (max-width: 374px) {

}

/*END BREAKPOINT MAX 374px-------------------------------------------------*/

@media only screen and (max-width: 320px) {

}

/*END BREAKPOINT MAX 320px-------------------------------------------------*/

/*==========  BREAKPOINTS  MIN-WIDTH ==========*/

@media only screen and (min-width: 320px) {

}

/*END BREAKPOINT MIN 320px-------------------------------------------------*/

@media only screen and (min-width: 375px) {

}

/*END BREAKPOINT MIN 375px-------------------------------------------------*/

@media only screen and (min-width: 480px) {

}

/*END BREAKPOINT MIN 480px-------------------------------------------------*/

@media only screen and (min-width: 768px) {

}

/*END BREAKPOINT MIN 768px-------------------------------------------------*/

@media only screen and (min-width: 1200px) {
  .plan-with-tools {
    h2, .h2 {
      font-size: 36px;
      letter-spacing: -0.51px;
      line-height: 49px;
    }
    p {
      font-size: 24px;
      letter-spacing: -0.34px;
      line-height: 33px;

    }
  }
}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/

@media only screen and (min-width: $screen-lg) {

}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/