/*HERO Headline*/

.heroHeadline {
  background: $color-4;
  text-align: center;
  padding: 50px 25px;
  .h1, h1 {
    color: #fff;
    font-size: 36px;
    margin: 0;
    padding: 0;
    line-height: 1;
    padding-left: 25px;
    padding-right: 25px;
  }
  p {
    font-weight: 600;
    font-size: 20px;
    color: #FFFFFF;
    letter-spacing: -0.28px;
    line-height: 27px;
    padding: 0 25px;
  }
}

/*==========  BREAKPOINTS  MAX-WIDTH ==========*/

@media only screen and (max-width: 1339px) {
  .heroHeadline {
    padding: 3.5vW 0;
    .h1 {
      font-size: 2.57vW;
    }
    p {
      font-size: 16px;
      color: #FFFFFF;
      letter-spacing: -0.23px;
      line-height: 22px;
    }
  }
}

@media only screen and (min-width: 999px) {
  .heroHeadline {
    p {
      display: inline-block;
      width: 75%;
    }
  }

}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/

@media only screen and (max-width: 767px) {
  .heroHeadline {
    padding: 3.5vW 0;
    .h1, h1 {
      font-size: 4.5vW;
      line-height: 1.2;
      hyphens: none !important;
    }
    p {
      font-size: 14px;
    }
  }
}

@media only screen and (max-width: 600px) {

}

/*END BREAKPOINT MAX 767px-------------------------------------------------*/

@media only screen and (max-width: 479px) {

}

/*END BREAKPOINT MAX 479px-------------------------------------------------*/

@media only screen and (max-width: 375px) {

}

/*END BREAKPOINT MAX 374px-------------------------------------------------*/

@media only screen and (max-width: 320px) {

}

/*END BREAKPOINT MAX 320px-------------------------------------------------*/

@media only screen and (min-width: 1000px) and (max-width: 1339px) {

}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/
@media only screen and (min-width: 1px) {
  .heroHeadline {
    h1, .h1, h2, .h2, h3, .h3, p, a {
      color: #fff;
    }
  }

}