/*Team Member*/

section.teamMember {
  [class*="col-"] {
    vertical-align: middle !important;
    text-align: left;
  }
  h1, .h1 {
    font-size: 36px;
    color: $color-2;
    letter-spacing: -0.51px;
    line-height: 49px;
  }
  .item {
    border-bottom: 3px solid $color-2;
    padding: 45px 0;
    &:last-child {
      border: none;
    }
  }
  h2, .h2 {
    font-weight: 600;
    font-size: 20px;
    color: $color-2;
    letter-spacing: -0.28px;
    line-height: 27px;
    margin: 0 0 30px 0;
  }
  p {
    margin-bottom: 20px;
    color: $grey;
  }
  a.mail, a.phone, .spacer {
    font-size: 20px;
    font-weight: 400;
    color: $color-3;
    letter-spacing: -0.28px;
    line-height: 27px;
    display: inline-block;
    text-decoration: none;
    transition: $t-fast;
    &:hover, &:focus {
      color: $color-2;
    }
  }
  .spacer {
    color: $color-2;
    padding: 0 30px;
  }
  figure {
    border-radius: 300px;
    overflow: hidden;
    display: inline-block;
    img {
      max-width: 250px;
    }
  }
}

/*==========  BREAKPOINTS  MAX-WIDTH ==========*/

@media only screen and (max-width: 1339px) {

}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/

@media only screen and (max-width: 999px) {
  section.teamMember {
    .item {
      h2, .h2, p, a, .spacer {
        font-size: 18px;
      }
    }
  }
}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/

@media only screen and (max-width: 767px) {
  section.teamMember {
    .item{
      padding: 30px 0;
    }
    h1, .h1 {
      font-size: 24px;
      letter-spacing: -0.34px;
      line-height: 33px;
    }
    .image {
      text-align: right;
    }
    a.mail, a.phone, .spacer {
      display: block;
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .spacer {
      display: none;
    }
  }
}

/*END BREAKPOINT MAX 767px-------------------------------------------------*/

@media only screen and (max-width: 600px) {
  section.teamMember {
    .item{
      &:last-child{
        padding-bottom: 0;
      }
    }
    [class*="col-"] {
      vertical-align: top !important;
    }
    .info {
      padding-left: 0;
    }
    .item {
      h2, .h2 {
        margin-bottom: 10px;
        p{margin-bottom: 10px;}
      }
      h2, .h2, p, a, .spacer {
        font-size: 14px;
      }
    }
  }
}

/*END BREAKPOINT MAX 479px-------------------------------------------------*/

@media only screen and (max-width: 374px) {

}

/*END BREAKPOINT MAX 374px-------------------------------------------------*/

@media only screen and (max-width: 320px) {

}

/*END BREAKPOINT MAX 320px-------------------------------------------------*/

/*==========  BREAKPOINTS  MIN-WIDTH ==========*/

@media only screen and (min-width: 320px) {

}

/*END BREAKPOINT MIN 320px-------------------------------------------------*/

@media only screen and (min-width: 375px) {

}

/*END BREAKPOINT MIN 375px-------------------------------------------------*/

@media only screen and (min-width: 480px) {

}

/*END BREAKPOINT MIN 480px-------------------------------------------------*/

@media only screen and (min-width: 768px) {

}

/*END BREAKPOINT MIN 768px-------------------------------------------------*/

@media only screen and (min-width: 1000px) {

}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/

@media only screen and (min-width: $screen-lg) {

}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/