body.p4b {
    padding-top: 148px !important;
    @media only screen and (max-width: 1060px) {
        padding-top: 125px !important;
    }
    @media only screen and (max-width: 767px) {
        padding-top: 82px !important;
    }
    header {
        background: #fff;
        border-width: 3px;
        padding-top: 20px;
        padding-bottom: 20px;
        position: fixed;
        .container {
            display: flex;
            align-items: center;
            > * {
                float: none;
            }
        }
        .logo {
            transform: none;
            svg {
                .p4b-fill {
                    fill: #000000;
                    transition: all 0.35s;
                }

                .p4b-line {
                    fill: none;
                    stroke-miterlimit: 10;
                    stroke-width: 2px;
                    stroke: #000000;
                    transition: all 0.35s;
                }
                .p4b-bg {
                    fill: #fff;
                    transition: all 0.35s;
                }
            }
            &:hover,
            &:active {
                svg {
                    .p4b-fill {
                        fill: #fff;
                        stroke-miterlimit: 10;
                        stroke-width: 0.5px;
                        stroke: #fff;
                    }

                    .p4b-line {
                        stroke: #fff;
                    }
                    .p4b-bg {
                        fill: rgba(var(--color-2), 1);
                        stroke: rgba(var(--color-2), 1);
                    }
                }
            }
        }
        .reg {
            display: none;
        }
        .hamburger {
            margin-left: auto;
            height: auto;
            margin-top: 0;
        }
        .hamburger {
            cursor: pointer;
            top: 12px;
        }
        a {
            color: rgba(var(--color-2), 1);
        }
        nav.mainNav {
            margin-left: auto;
            li {
                margin-left: 12px;
                a {
                    font-weight: 800;
                    font-size: 24px;
                    padding: 8px 14px;
                    @media screen and (min-width: 1060px) {
                        border: 1px solid #fff;
                    }
                    &:after {
                        content: none;
                    }
                    &:hover {
                        border-color: rgba(var(--color-2), 1);
                    }
                }
                &.active {
                    a {
                        color: #fff;
                        @media screen and (min-width: 1060px) {
                            background-color: rgba(var(--color-2), 1);
                            border-color: rgba(var(--color-2), 1);
                        }
                        span {
                            color: #fff;
                        }
                    }
                }
            }
        }
        .burgerFill {
            transition: fill 0.5s;
            fill: rgba(var(--color-2), 1);
        }
        @media only screen and (max-width: 1060px) {
            nav.mainNav ul {
                background: rgba(var(--color-4), 0.92);
                li {
                    border-color: transparentize($color: #fff, $amount: 0.5);
                    a {
                        color: #fff !important;
                        opacity: 0.65;
                        text-decoration: none !important;
                        &:hover {
                            opacity: 1;
                        }
                    }
                    &.active {
                        a {
                            opacity: 1;
                        }
                    }
                }
            }
        }
        @media only screen and (max-width: 1060px) {
            .logo {
                width: 175px;
            }
        }
        @media only screen and (max-width: 767px) {
            padding: 10px 0 !important;
            .logo {
                width: 125px;
            }
        }
    }
    &.navOpen {
        .burgerFill {
            fill: #fff;
        }
        section,
        footer {
            opacity: 0.25;
        }
    }
    footer {
        @media screen and (max-width: 1339px) {
            .container {
                width: 100% !important;
            }
        }
        * {
            color: #fff;
        }
        a {
            &:hover,
            &:focus,
            &:active {
                color: transparentize($color: #fff, $amount: 0.3);
            }
        }
        .copyright {
            > .container {
                &::before,
                &::after {
                    content: none;
                }
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
            }
            .footerNav,
            .social,
            .logobox {
                flex: 1;
            }
            @media screen and (max-width: 500px) {
                .social {
                    flex: 1 0 100%;
                    max-width: 100%;
                    text-align: center !important;
                    justify-content: center;
                    order: -1;
                    margin-bottom: 15px;
                    a{
                        width: 44px !important;
                        height: 44px !important;
                        padding: 10px !important;
                    }
                }
                .footerNav, .logobox{
                    display: none;
                }
            }
            .social {
                text-align: right;
                a {
                    width: 36px;
                    height: 36px;
                    border-width: 2px;
                    margin-left: 4px;
                    position: relative;
                    background-color: transparent;
                    &:hover,
                    &:focus {
                        border-color: transparent;
                        z-index: 10;
                        transform: scale(1.5);
                    }
                }
                a.twitter {
                    margin: 0;
                }
                a.instagram {
                    padding: 4px;
                }
            }

            .logobox {
                .faz-logos {
                    display: flex;
                    flex-wrap: wrap;
                    .faz {
                        flex: 1;
                        margin: 25px 10px;
                        &:hover {
                            opacity: 0.75;
                        }
                    }
                }
                @media screen and (max-width: 767px) {
                    flex: 1 0 100%;
                    border-top: 1px solid rgba(255, 255, 255, 0.5);
                    order: 3;
                    padding-top: 30px;
                    .faz-logos {
                        max-width: 300px;
                        margin: 0 auto;
                    }
                }
            }
            .cr {
                flex: 1 0 100%;
                @media screen and (max-width: 767px) {
                    order: 5;
                }
            }
        }
    }
}
