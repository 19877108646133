$slick-font-path: "../../../../Gulp/bower_components/slick-carousel/slick/fonts/" !default;
$slick-font-family: "slick" !default;
$slick-arrow-color: #ccc !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\2190" !default;
$slick-next-character: "\2192" !default;
$slick-dot-character: "\2022" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

@font-face {
  font-family: 'slick';
  font-weight: normal;
  font-style: normal;

  src: url('../../Gulp/bower_components/slick-carousel/slick/fonts/slick.eot');
  src: url('../../Gulp/bower_components/slick-carousel/slick/fonts/slick.eot?#iefix') format('embedded-opentype'), url('../../Gulp/bower_components/slick-carousel/slick/fonts/slick.woff') format('woff'), url('../../Gulp/bower_components/slick-carousel/slick/fonts/slick.ttf') format('truetype'), url('../../Gulp/bower_components/slick-carousel/slick/fonts/slick.svg#slick') format('svg');
}

.modalpreload {

  img.loadergif {
    position: absolute;
    width: 50px;
    height: auto;
    top: 50%;
    left: 50%;
    margin: -25px 0 0 -25px;
  }
}

.modal-header {
  text-align: right;
  border:none;
  padding:8px 15px 0 15px;
  button {
    background: transparent;
    border: none;
    font-size:30px;
    padding:0;

  }
}

.modal-body {
  position: relative;
  padding: 10px 40px;
}

.modal-lg {
  max-width: 800px;
}

.modalslider {
  visibility: hidden;
  &.slick-initialized {
    visibility: visible;
  }
  img {border:1px solid #fff; display:block;}
  div.h2 {
    font-size: 1.7em;
    line-height: 120%;
    margin-top: 20px;
    margin-bottom: 8px;
    color: $color-2;
  }
  div.modal-text p {
    font-size: 17px;
    font-weight: 400;
    color: $font-color;
    line-height: 130%;
    margin-bottom: 30px;
  }
  .slick-slide {
    height: auto;
  }
}

/* Arrows */

.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 30px;
  width: 30px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 35%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
  z-index: 40;
  &:hover, &:focus {
    outline: none;
    background: transparent;
    color: transparent;
    &:before {
      opacity: $slick-opacity-on-hover;
    }
  }
  &.slick-disabled:before {
    opacity: $slick-opacity-not-active;
  }
  &:before {
    font-family: $slick-font-family;
    font-size: 30px;
    line-height: 1;
    color: $slick-arrow-color;
    opacity: $slick-opacity-default;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.slick-prev {
  left: -35px;
  [dir="rtl"] & {
    left: auto;
    right: -35px;
  }
  &:before {
    content: $slick-prev-character;
    [dir="rtl"] & {
      content: $slick-next-character;
    }
  }
}

.slick-next {
  right: -35px;
  [dir="rtl"] & {
    left: -35px;
    right: auto;
  }
  &:before {
    content: $slick-next-character;
    [dir="rtl"] & {
      content: $slick-prev-character;
    }
  }
}

@media only screen and (max-width: 800px) {
  .modalslider {
    visibility: hidden;
    &.slick-initialized {
      visibility: visible;
    }
    div.h2 {
      font-size: 1.0em;
      line-height: 120%;
      margin-top: 20px;
      margin-bottom: 8px;
      color: $color-2;
    }
    div.modal-text p {
      font-size: 13px;
      font-weight: 400;
      color: $font-color;
      line-height: 130%;
      margin-bottom: 30px;
    }
    .slick-slide {
      height: auto;
    }
  }
}