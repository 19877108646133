// ctype SCSS
.ce-tiles3col {
  .image-link {
    display: block;
    overflow: hidden;

    img {
      transition: all .5s;
      display: inline-block;
    }

    &:hover, &:focus, &:active {
      img {
        transform: scale(1.1);
      }
    }
  }

  h2, h3 {
    .hl-link {
      display: block;
      text-decoration: none;
      transition: color .25s;
      color: $color-3;

      &:hover, &:focus, &:active {
        color: $color-2;
      }
    }
  }

  .tile-item {
    background: $sectionBG-2;
    margin: 25px 0;
  }

  .tile-content {
    padding: 25px;
    display: flex;
    flex-direction: column;
    flex: 1;
    @media all and (min-width: 768px) and (max-width: 999px) {
      padding: 12px;
      p {
        font-size: 15px;
      }
    }

    p a {
      text-decoration: underline;
      color: $color-2;
    }

    p:last-of-type, ul:last-of-type {
      margin-bottom: 25px;
    }

    a.btn {
      margin-top: auto;
      color: #fff;
    }
  }

  &.bgGreen, &.bgGreyLighter {
    .tile-item {
      background: #fff;

      p {
        color: $grey;
      }
    }
  }
}
