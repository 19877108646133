.ce-standardtextimage{
  figure{
    width: 100%;
    max-width: 50%;
    margin-bottom: 25px;
  }
  .inTextLeft{
    figure{
      float: left;
      margin-right: 50px;

    }
  }
  .inTextRight{
    figure{
      float: right;
      margin-left: 50px;
    }
  }
  .belowLeft{
    figure{
      margin: 25px 0 0 0;
    }
  }
  .besideTextLeft, .besideTextRight{
    figure{
      width: 100%;
      max-width: 100%;
      margin: 0;
    }
  }
  @media all and (max-width: 999px){
    .besideTextLeft{
       figure{
         margin-bottom:25px;
       }
     }
    .besideTextRight{
      figure{
        margin-top:25px;
      }
    }
  }
  @media all and (max-width: 767px){
    figure{
      width: 100%;
      max-width: 100% !important;
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
}

