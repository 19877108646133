/*FORMS ETC*/

form.uh-form{
  .form-group{
    margin-bottom: 5px;
  }
  ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color:    #fff;
    font-weight:600;
    font-size:$fontsize-base;
  }
  :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color:    #fff;
    opacity:  1;
    font-weight:600;
    font-size:$fontsize-base;
  }
  ::-moz-placeholder { /* Mozilla Firefox 19+ */
    color:    #fff;
    opacity:  1;
    font-weight:600;
    font-size:$fontsize-base;
  }
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color:    #fff;
    font-weight:600;
    font-size:$fontsize-base;
  }
.radio, .checkbox{
  label{
    margin-right: 15px;
  }
}
  input{
    transition: $t-medium;
    &[type="radio"], &[type="checkbox"] {
    margin-top: 0;
    }
    &[type="text"], &[type="password"], &[type="email"]{
      font-size: 18px;
      height: 48px;
      display: block;
      width: 100%;
      padding: 10px 15px;
      background: rgba(255,255,255,0.5);
      color: #fff;
      border:none;
      outline: none;
      &:focus{
        background: rgba(var(--color-2), .5);
      }
    }
  }
}
/*==========  BREAKPOINTS  MAX-WIDTH ==========*/


@media only screen and (max-width: 1339px) {

}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/


@media only screen and (max-width: 999px) {

}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/


@media only screen and (max-width: 767px) {

}

/*END BREAKPOINT MAX 767px-------------------------------------------------*/


@media only screen and (max-width: 479px) {

}

/*END BREAKPOINT MAX 479px-------------------------------------------------*/


@media only screen and (max-width: 374px) {

}

/*END BREAKPOINT MAX 374px-------------------------------------------------*/


@media only screen and (max-width: 320px) {

}

/*END BREAKPOINT MAX 320px-------------------------------------------------*/



/*==========  BREAKPOINTS  MIN-WIDTH ==========*/


@media only screen and (min-width: 320px) {

}

/*END BREAKPOINT MIN 320px-------------------------------------------------*/


@media only screen and (min-width: 375px) {

}

/*END BREAKPOINT MIN 375px-------------------------------------------------*/


@media only screen and (min-width: 480px) {

}

/*END BREAKPOINT MIN 480px-------------------------------------------------*/

@media only screen and (min-width: 768px) {

}

/*END BREAKPOINT MIN 768px-------------------------------------------------*/


@media only screen and (min-width: 1000px) {

}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/

@media only screen and (min-width: $screen-lg) {

}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/