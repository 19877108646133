body.uhLP {
    padding-top: 0px !important;

    .container {
        width: 100%;
    }

    h1 {
        font-size: 42px;
        line-height: 1.3;
    }

    h3, div.h3, h2, div.h2 {
        font-size: 24px;
        line-height: 1.3;
    }

    .pageBox {
        max-width: 1000px;
        margin: 0 auto;
    }

    .btn, section.text-image article .btn {
        font-size: 22px;
        border-radius: 6px;
        padding: 10px 20px;
        line-height: 30px;
        background-color: #5255d2;
        &:before {
            background: #7275f2;
        }
    }

    img {
        filter: grayscale(0%);
        transition: none;
    }

    section.text-image {
        article {
        }

        .col-image {
            width: 45%;
        }

        .col-text {
            margin-right: 0;
            margin-left: 0;
            width: 55%;
        }

        @media all and (max-width: 767px) {
            .col-text, .col-image {
                width: 100%;
            }
        }

        &.hero {
            .partner-roofline span {
                display: inline-block;
                border-radius: 6px;
                background: #ccc;
                font-size: 16px;
                padding: 10px;
                margin-bottom: 50px;
            }

            @media all and (max-width: 767px) {
                .col-sm-12 {
                    margin-bottom: 20px;
                }
            }
        }
    }

    section.ce-partnerlogos {
        .col-sm-24 {
            text-align: center;

            img {
                width: auto;
                height: auto;
                display: inline-block;
                margin-right: 40px;
                margin-bottom: 20px;
                vertical-align: middle;

                &:last-of-type {
                    margin-right: 0;
                }
            }
        }
    }

    section.ce-keyreasons {
        .wrapper {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
        }

        h2 {
            font-size: 24px;
        }

        h3 {
            font-size: 17px;
        }

        .col-sm-8 {
            text-align: center;

            img {
                width: auto;
                height: 100px;
                display: inline-block;
                margin-bottom: 5px;

            }

        }

        @media all and (max-width: 767px) {
            .col-sm-8 {
                padding-bottom: 40px;
            }
        }
    }

    section.ce-faq {
        .container {
            max-width: 1000px;
        }
    }

    .collapse {
        display: none;

        &.show, &.in {
            display: block;
        }
    }

    .collapsing {
        position: relative;
        height: 0;
        overflow: hidden;
        transition-property: height, visibility;
        transition-duration: .35s;
    }

    .faq_question {
        color: $grey;
        font-size: 1.17em;
        line-height: 1;
        cursor: pointer;
        margin: 0 0 20px 0;
        padding-left: 34px;
        position: relative;

        .toggler {
            position: absolute;
            left: 0;
            width: 20px;
            height: 20px;
            line-height: 20px;
            font-size: 16px;
            font-weight: normal;
            transform: translateY(5px);
            text-align: center;
            display: inline-block;
            background: rgba(var(--grey), .13);
            border-radius: 50%;
            margin-right: 10px;
            transition: background-color .3s;
            color: $grey;

            &:before, &:after {
                content: "";
                position: absolute;
                display: block;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 8px;
                height: 2px;
                background: currentColor;
                transition: all .25s;
            }

            &:after {
                transform: translate(-50%, -50%) rotate(90deg);
            }
        }

        &:hover {
            .toggler {
                color: #fff;
                background: $color-2;
            }
        }

        &[aria-expanded="true"] {
            .toggler {
                color: #fff;
                background: $color-2;

                &:after {
                    transform: translate(-50%, -50%) rotate(90deg) scaleY(0);
                }
            }
        }
    }

    .faq_answer {
        color: $grey;
        padding: 0 0 23px 34px;

        ul + p, ol + p {
            padding-top: 20px;
        }
    }

    section.ce-partnerlist {
        .intro {
            padding-bottom: 40px !important;

            p {
                font-size: 22px;
                margin-top:5px;
            }
        }

        h3, div.h3 {
            margin-bottom: 0;
        }

        .btn {
            min-width: unset;
            width: 100%;

            span {
                color: #fff;
            }
        }
    }

    .partnerlist-item {

        .text {
            padding-right: 10px;
        }

        .image {
            padding-left: 10px;
        }


    }

    &.index {
        .pageBox {
            max-width: 1384px;
            margin: 0 auto;
        }

        background-color: #F3F3F3;

        .row {
            display: flex;
            flex-wrap: wrap;
        }

        div.row > .col-sm-12:not(.text, .image) {
            border-radius: 4px;
            background-color: #fff;
            width: calc(50% - 20px);
            margin: 0 10px;
            box-shadow: 0 0 15px -1px rgba(0, 0, 0, 0.1);
            padding-top: 15px;
            padding-bottom: 15px;
            display: flex;
            flex-direction: column;
            margin-bottom: 20px;

        }

        @media all and (max-width: 767px) {
            .row, div.row > .col-sm-12:not(.text, .image) {
                display: block;
                flex-wrap: unset;

            }
            div.row > .col-sm-12:not(.text, .image) {
                width: 100%;
                margin: 0 0 20px 0;
            }
            .btn {
                margin-bottom: 25px;
            }
        }
    }
}
