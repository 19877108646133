/*HERO Slider*/
section.hero {
  padding: 0;
  position: relative;
  z-index: 12;
  overflow: hidden;

  .overlay {
    background: rgba(var(--color-3), .39);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    color: #fff;
    @media (max-width: 1339px) {
      .container {
        width: 100%;
      }
    }

    .derHeld {
      display: none;
    }

    a {
      color: #fff;
    }

    .btn {
      border: none;
      margin: 2.8vW 0 10px;
    }

    h1, .h1 {
      font-size: 3.435vW;
      font-weight: 600;
      color: #fff;
      position: relative;
      display: inline-block;
      padding-bottom: 0;
      margin: 0;

      span {
        display: block;
      }

      h1, span, ul li {
        text-shadow: 0 -1px 5px rgba(78, 108, 117, 0.5);
      }
      h1{
        margin: 0;
      }
    }

    ul {
      //position: absolute;
      left: 0;
      margin: 1.1vW 0 0 0;
      padding: 0;
      padding-left:0;
      text-align: left;

      li {
        font-size: 2vW;
        font-weight: 600;
        white-space: nowrap;
      }

      &.hero-ul.checked {
        li {
          white-space: normal;
          padding-left: 1.2em;
          margin-left:0;
          &:before {
            background-image: url("../Img/icons/hook_white.svg");
          }
        }
      }
    }
  }

  &.sub {
    .overlay {
      overflow: hidden;
      background: rgba(var(--color-4), .35);
      height: 100%;

      .derHeld {
        width: auto;
        height: 100%;
        max-height: calc(100% - 50px);
        display: block;

        img {
          display: block;
          width: auto;
          height: 100%;
          object-fit: contain;
        }
      }

      .container {
        display: flex;
        align-items: flex-end;
        height: 100%;
        position: relative;

        h1, .h1 {
          text-align: left;
          font-size: 36px;
          color: #ffffff;
          font-weight: 600;
          letter-spacing: -1px;
          line-height: 49px;
          display: block;
          flex: 1;
          align-self: center;

          .btn {
            line-height: $fontsize-base;
          }
        }
      }
    }
  }
}

/*==========  BREAKPOINTS  MAX-WIDTH ==========*/

@media only screen and (max-width: 1339px) {
  section.hero {

    &.sub {

      .overlay {

        .container {

        }
      }
    }
  }
}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/

@media only screen and (max-width: 999px) {
  section.hero {
    > img {
    }

    .overlay {
      padding: 0;

      h1, .h1 {
        text-align: left;
        display: block;
        padding-bottom: 0;
        font-size: 4.5vW;
        line-height: 1.37;
      }

      ul {
        position: static;
        padding-left: 22px;
      }
    }

    &.sub {
      > img {
      }

      .overlay {
        .derHeld {
        }

        .container {

          h1, .h1 {
            font-size: 4vW;
            line-height: 1.2;
          }

        }
      }
    }
  }
}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/

@media only screen and (max-width: 767px) {
  section.hero {
    > img {
      width: 175%;
    }

    .overlay {
      .btn {
        font-size: 16px;
      }

      h1, .h1 {
        font-size: 4.5vW;
      }

      ul {
        li {
          font-size: 2.8vW;
        }
      }
    }

    &.sub {
      > img {

      }

      .overlay {
        .derHeld {
          max-height: calc(100% - 30px);
          margin-left: -4%;
        }

        .container {
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  section.hero {
    &.sub {
      .overlay {
        .container {
        }

        .derHeld {
        }
      }
    }
  }
}

/*END BREAKPOINT MAX 767px-------------------------------------------------*/

@media only screen and (max-width: 479px) {
  section.hero {
    > img {
      width: 250%;
    }

    .overlay {
      padding: 10px 0;

      h1, .h1 {
        font-size: 24px;
      }

      ul {
        li {
          font-size: $fontsize-base;
          white-space: normal;
          line-height: 1.2;
          margin-bottom: 8px;
        }
      }
    }

    &.sub {
      > img {

      }

      .overlay {
        padding-bottom: 0;

        ul {
          margin-top: 10px;
        }

        .container {
          h1, .h1 {
            font-size: 24px;
          }
        }

        .derHeld {
          margin-left: -20%;
        }
      }
    }
  }
}


/*END BREAKPOINT MAX 479px-------------------------------------------------*/

@media only screen and (max-width: 400px) {
  section.hero {
    > img {
    }

    .overlay {
      ul li {
        display: none !important;
      }

      h1, .h1 {
        font-size: 24px;
      }

      ul {
        li {
          font-size: $fontsize-base;
          white-space: normal;
          line-height: 1.2;
          margin-bottom: 8px;
        }
      }
    }

  }
}

/*END BREAKPOINT MAX 374px-------------------------------------------------*/

@media only screen and (max-width: 320px) {
  section.hero {
    .overlay {
      h1, .h1 {
        font-size: 22px;
      }
    }
  }
}

/*END BREAKPOINT MAX 320px-------------------------------------------------*/

@media only screen and (min-width: 768px) and (max-width: 1339px) {
  section.hero.sub {
    > img {
      width: 125%;
      transform: translateX(-11.5%);
    }

    .overlay .derHeld {
    }
  }
}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/
@media only screen and (min-width: 1400px) {
  section.hero {
    .overlay {
      h1, .h1 {
        margin-top: 25px;
        font-size: 48px;
        //padding-bottom: 176px;
      }

      ul {
        margin-top: 16px;
        padding-left: 0;

        li {
          font-size: 24px;
        }
      }

      .btn {
        margin: 40px 0 0;
      }
    }

  }

}