body.p4b {
    @media screen and (max-width: 549px) {
        h1,
        .h1,
        h2,
        .h2,
        h3,
        .h3,
        p,
        li {
            hyphens: auto;
        }
    }
    h1,
    .h1,
    h2,
    .h2,
    h3,
    .h3 {
        font-weight: 800;
    }
    h1,
    .h1 {
        font-size: 36px;
        line-height: 1.06;
    }
    h2,
    .h2 {
        font-size: 28px;
        line-height: 1.06;
    }
    .textonly,
    section {
        h2,
        .h2 {
            font-size: 32px;
            line-height: 1.06;
        }
        h3,
        .h3,
        p,
        ul li,
        ol li,
        a,
        p a,
        li a {
            color: $font-color;
        }
        ul{
            li{
                margin-bottom: .5em;
            }
        }
        ol{
            li{
                margin-bottom: .25em;
            }
        }
    }
    p,
    .text,
    address,
    ul li,
    ol li {
        line-height: 1.45;
    }
}
