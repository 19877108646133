.priceFeatures {
  .item {
    text-align: center;
    figure {
      display: inline-block;
      width: 100%;
      max-width: 250px;
      margin-bottom: 40px;
    }
    h2, .h2 {
      text-align: left;
    }
    p {
      text-align: left;
    }
    ul {
      li {
        text-align: left;
      }
    }
  }
}

/*==========  BREAKPOINTS  MAX-WIDTH ==========*/

@media only screen and (max-width: 1339px) {

}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/
@media only screen and (max-width: 1100px) {
  .priceFeatures {
    .item {
      figure {
      }
      h2, .h2 {
        font-size: 24px;
        line-height: 32px;
      }
      p {
      }
      ul {
        li {
        }
      }
    }
  }
}

@media only screen and (max-width: 999px) {
  .priceFeatures {
    .item {
      margin-bottom: 70px;
      figure {
      }
      h2, .h2 {
        font-size: 18px;
        line-height: 24px;
      }
      p {
        font-size: 14px;
        line-height: 19px;
      }
      ul {
        li {
          font-size: 14px;
          line-height: 19px;
        }
      }
    }
  }
}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/

@media only screen and (max-width: 767px) {
  .priceFeatures {
    .item {
      margin-bottom: 70px;
      figure {
      }
      h2, .h2 {
      }
      p {
      }
      ul {
        li {
        }
      }
    }
    [class*="col-"]:last-child {
      .item {
        margin-bottom: 0;
      }
    }
  }
}

/*END BREAKPOINT MAX 767px-------------------------------------------------*/

@media only screen and (max-width: 479px) {

}

/*END BREAKPOINT MAX 479px-------------------------------------------------*/

@media only screen and (max-width: 374px) {

}

/*END BREAKPOINT MAX 374px-------------------------------------------------*/

@media only screen and (max-width: 320px) {

}

/*END BREAKPOINT MAX 320px-------------------------------------------------*/

/*==========  BREAKPOINTS  MIN-WIDTH ==========*/

@media only screen and (min-width: 320px) {

}

/*END BREAKPOINT MIN 320px-------------------------------------------------*/

@media only screen and (min-width: 375px) {

}

/*END BREAKPOINT MIN 375px-------------------------------------------------*/

@media only screen and (min-width: 480px) {

}

/*END BREAKPOINT MIN 480px-------------------------------------------------*/

@media only screen and (min-width: 768px) {

}

/*END BREAKPOINT MIN 768px-------------------------------------------------*/

@media only screen and (min-width: 1000px) {

}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/

@media only screen and (min-width: $screen-lg) {

}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/