/*Ankernavi*/

/*==========  BREAKPOINTS  MAX-WIDTH ==========*/
.ankernav {
  .row > .col-xs-8 {
    &:nth-child(2) {
      border-left: 1px solid $color-2;
      border-right: 1px solid $color-2;
    }
  }
  a {
    display: block;
    font-size: 1.5vW;
    color: $color-3;
    font-weight: 600;
    letter-spacing: -0.34px;
    line-height: 1.3;
    background: #fff;
    text-decoration: none;
    padding: 2.5vW 0;
    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 0;
      top: 0;
      left: 0;
      background: $color-2;
      transition: $t-fast;
    }
    span {
      display: inline-block;
      vertical-align: middle;
      width: calc(70% - 10px);
      padding-left: 1vW;
      transition: $t-medium;
    }
    img {
      display: inline-block;
      vertical-align: middle;
      max-width: 100px;
      width: 30%;
      height: auto;
      max-height:70px;
      transition: $t-medium;
    }
    &:hover, &:focus {
      &:after {
        height: 7px;
      }
      img {
        transform: scale(1.1);
      }
      span {
        transform: translateY(15px);
      }
    }
  }
  &.bgGreenBlue {
    text-align: center;
    .row > .col-xs-8 {
      border: none !important;
      padding: 0 10px;
    }
    a {
      font-size: $fontsize-base;
      background: none;
      color: #fff;
      &:after {
        background: #fff;
      }
      img {
        width: 100%;
        max-width: 120px;
      }
      span {
        display: block;
        color: #fff;
        width: 100%;
        padding: 0;
        margin-top: 7px;
      }
    }
  }
}

@media only screen and (max-width: 1340px) {
  .ankernav .container {
    width: 100%;
  }
}
@media only screen and (max-width: 1200px) {
  .ankernav.bgGreenBlue {
    a {
      span {
        font-size: 16px;
      }
    }
  }
}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/

@media only screen and (max-width: 999px) {
  .ankernav {
    a {
      font-size: 2vW;
    }
  }
  .ankernav.bgGreenBlue {
    a {
      img {
        max-width: 80px;
      }
      span {
        font-size: 12px;
      }
    }
  }
}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/

@media only screen and (max-width: 767px) {
  .ankernav.bgGreenBlue a {
    span {
      font-size: 16px;
    }
    &:after {
      display: none;
    }
    &:hover, &:focus {
      span {
        transform: translateY(7px);
      }
    }
  }
}

@media only screen and (max-width: 550px) {
  .ankernav {
    a {
      font-size: 4vW;
      padding: 3vW 0;
      &:after{display: none;}
      span {
        padding-left: 3vW;
      }
      img {
        max-width: 75px;
      }
      &:hover, &:focus{
        span{
          transform: translate3D(15px,0,0);
        }
      }
    }
    &:not(.bgGreenBlue) {
      .row .col-xs-8 {
        width: 100%;
        display: block;
        float: none;
        &:nth-child(2) {
          border: none;
          border-top: 1px solid $color-2;
          border-bottom: 1px solid $color-2;
        }
      }
    }
  }
}

/*END BREAKPOINT MAX 767px-------------------------------------------------*/

@media only screen and (max-width: 479px) {
  .ankernav {
    a {
      font-size: 5vW;
    }
    &.bgGreenBlue{
      a{
        span{
          font-size: 12px;
        }
      }
    }
  }
}

/*END BREAKPOINT MAX 479px-------------------------------------------------*/

@media only screen and (max-width: 374px) {

}

/*END BREAKPOINT MAX 374px-------------------------------------------------*/

@media only screen and (max-width: 374px) {
  .ankernav.bgGreenBlue {
    .row > .col-xs-8 {
      width: 50% !important;
    }
  }
}

/*END BREAKPOINT MAX 320px-------------------------------------------------*/

/*==========  BREAKPOINTS  MIN-WIDTH ==========*/

@media only screen and (min-width: 320px) {

}

/*END BREAKPOINT MIN 320px-------------------------------------------------*/

@media only screen and (min-width: 375px) {

}

/*END BREAKPOINT MIN 375px-------------------------------------------------*/

@media only screen and (min-width: 480px) {

}

/*END BREAKPOINT MIN 480px-------------------------------------------------*/

@media only screen and (min-width: 768px) {

}

/*END BREAKPOINT MIN 768px-------------------------------------------------*/

@media only screen and (min-width: 1000px) {

}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/

@media only screen and (min-width: $screen-lg) {
  .ankernav a {
    font-size: 22px;
    padding: 35px 0;
  }
}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/