body.p4b {
    section {
        p,
        ul li,
        ol li,
        &.textonly {
            .container{
                > *:last-child{
                    margin-bottom: 0;
                }
            }
            a {
                &:hover,
                &:focus {
                    opacity: 0.75;
                }
            }
        }
        &.bgGreen {
            p,
            a {
                color: #fff;
            }
            a {
                &:hover {
                    color: #999;
                }
            }
        }
        &.hero {
            &.sub > img {
                width: 100%;
                transform: none;
            }
            & .overlay {
                background: transparent;
            }
            ul {
                li {
                    color: #fff;
                    a {
                        color: currentColor;
                    }
                }
            }
        }
        &.textonly {
            .container {
                max-width: 100%;
            }
        }
        &.newsslider  {
            padding-top:0;
        }
        &.faq_question {
            color: $font-color;
            .toggler {
                color: #fff;
                background: $font-color;
                border: 2px solid $font-color;
                transform: scale(1.5);
            }
            &:hover {
                .toggler {
                    background-color: #fff;
                    color: $font-color;
                }
            }
        }
        &.press-social {
            padding-top: 51px;
            h2,
            .uh-pressekontakt .info,
            .uh-pressekontakt .info a {
                color: #000;
            }
            .uh-social {
                background-color: #fff;
                display: flex;
                flex-wrap: wrap;
                a{
                    margin: 0;
                    max-width: none;
                    width: auto;
                    &.btn{
                        align-self: flex-end;
                        flex: 1 0 100%;
                        width: 100%;
                        margin-top: 25px;
                    }
                    &:hover{
                        transform: none;
                    }
                }
                .p4b-anmeldung {
                    display: flex;
                    text-align: left;
                    align-items: flex-start;
                }
                .mail-icon {
                    width: 50px;
                    flex: 1 0 50px;
                    margin-right: 25px;
                    svg {
                        width: 100%;
                        height: 100%;
                    }
                }
                @media screen and (max-width: 460px){
                    display: block;
                    .p4b-anmeldung{
                        display: block;
                    }
                    .mail-icon{
                        margin-bottom: 15px;
                    }
                    p, a{
                        font-size: 18px;
                    }
                }

            }

        }
    }

    .ce-tiles3col.bgWhite{
        + .ce-tiles3col.bgWhite{
            padding-top: 0;
            margin-top: -50px;
        }
    }
}
