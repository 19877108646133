/*BASE*/
body, html {
  min-height: 100%;
}

body {
  background: $bodyBG;
  font-display: swap;
  &.navOpen {
    overflow: hidden;
    overflow-y: hidden;
    overflow-x: hidden;
    height: 100vH;
    max-height: 100vH;
  }
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #fff;
}
::-webkit-scrollbar-track:vertical {
  border: none;
  background-color: #fff;
}

::-webkit-scrollbar-thumb:vertical {
  background: $color-4;
  border-radius: 4px;
}
::-webkit-scrollbar-track:horizontal {
  background-color: #fff;
}
::-webkit-scrollbar-thumb:horizontal {
  background: $color-4;
  border-radius: 4px;
  height: 8px;
}

.wrap {
  max-width: 1400px;
  margin: 0 auto;
  background: #fff;
  //min-height: 100vH;
  overflow: hidden;
}

* {
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
  &:focus{
    outline:none !important;
  }
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}
img {
  display: block;
  width: 100%;
  height: auto;
}

figure, blockquote {
  margin: 0;
}

.row.row-flex {
  display: flex;
  flex-wrap: wrap;
  > [class*="col-sm"] {
    @media (min-width: 768px){
      display: flex;
      flex:1 0 33%;
      > div{
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
      }
    }
  }
}

.row.centered {
  text-align: center;
  vertical-align: top;
  [class*="col-"] {
    margin-right: -4px;
    display: inline-block;
    vertical-align: top;
    float: none;
  }
}
.centered {
  text-align: center;
}
.table{
  display: table;
  width: 100%;
  margin-bottom: 24px;
  min-width: 700px;
  color: $font-color;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.6128;
  a {
    color: $grey;
    font-size: 16px !important;
    transition: $t-fast;
    text-decoration: none;
    border-bottom: 1px solid $color-2;

    &:hover, &:focus {
      color: $color-2;
    }
  }
  tr{
    background: #fff;
    &:nth-child(odd){
      background: $sectionBG-2;
    }
    td{
      padding: 8px 5px;
      color: $grey;
    }
  }
  thead{
    tr{
      th{
        color: $color-2;
        text-align: left;
        padding-bottom: 15px;
        font-size: $fontsize-base;
        letter-spacing: -0.26px;
        line-height: 24px;
      }
    }
  }
  tbody{
    vertical-align: top;
  }
  .tableCell{
    display: table-cell;
    vertical-align: middle;
  }
}
.table-responsive{
  overflow: auto;
  margin-bottom: 25px;
  table{
    margin-bottom: 0;
  }
}

.scrollTop {
  display: none;
  position: fixed;
  z-index: 99999;
  right: 20px;
  bottom: 27px;
  width: 54px;
  height: 54px;
  text-align: center;
  line-height: 48px;
  border-radius: 50%;
  overflow: hidden;
  background: $color-2;
  border: 6px solid #fff;
  transition: $t-fast;
  cursor: pointer;
  .navOpen & {
    display: none !important;
  }
  img {
    width: 25px;
    height: auto;
    display: inline-block;
    margin-bottom: 2px;
  }
  &:hover {
    background: $color-3;
  }
}

.container-small{
  @extend .container;
  max-width: 1000px;
}
@media only screen and (max-width: 1280px) {
  .container:not(.scroll-vert){
    width: 100% !important;
    padding: 0 30px;
    .row{
      margin: 0 -15px;
      [class*="col-"]{
        padding: 0 15px;
      }
    }
  }
}


/*==========  BREAKPOINTS  MAX-WIDTH ==========*/

@media only screen and (max-width: 1599px) {
  .scrollTop {
    transform: scale(0.55);
    right: 0;
    bottom: 0;
  }
}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/

@media only screen and (max-width: 999px) {

}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/

@media only screen and (max-width: 767px) {
  .scrollTop {
    transform: scale(0.55);
    right: 0;
    bottom: 0;
  }
  .row.centered {
    text-align: center;
    .col-lg-24, .col-md-24, .col-sm-24, .col-xs-24 {
      margin-right: 0;
      width: 100%;
      display: block;
      float: none;
    }
  }
}

/*END BREAKPOINT MAX 767px-------------------------------------------------*/

@media only screen and (max-width: 479px) {

}

/*END BREAKPOINT MAX 479px-------------------------------------------------*/

@media only screen and (max-width: 374px) {

}

/*END BREAKPOINT MAX 374px-------------------------------------------------*/

@media only screen and (max-width: 320px) {

}

/*END BREAKPOINT MAX 320px-------------------------------------------------*/

/*==========  BREAKPOINTS  MIN-WIDTH ==========*/

@media only screen and (min-width: 320px) {

}

/*END BREAKPOINT MIN 320px-------------------------------------------------*/

@media only screen and (min-width: 375px) {

}

/*END BREAKPOINT MIN 375px-------------------------------------------------*/

@media only screen and (min-width: 480px) {

}

/*END BREAKPOINT MIN 480px-------------------------------------------------*/

@media only screen and (min-width: 768px) {

}

/*END BREAKPOINT MIN 768px-------------------------------------------------*/

@media only screen and (min-width: 1000px) {

}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/

@media only screen and (min-width: $screen-lg) {

}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/
