/*NEWS*/

section.newsslider {
  background: $sectionBG-1;
  padding-bottom: 0;

  h2 {
    font-size: 36px;
    color: $color-2;
    letter-spacing: -0.4px;
    line-height: 49px;
  }

  .slider {
    background: url("../Img/home/news.jpg") no-repeat center center;
    background-size: cover;

    .slide {
      display: block;
      width: 100%;

      .newsBox {
        position: relative;
        width: 100%;
        max-width: 635px;
        background: #fff;
        margin: 100px auto;
        padding: 40px 50px 60px 50px;

        a {
          text-decoration: none;
        }

        h3 {
          margin: 0 0 25px 0;
          font-size: 28px;
          color: $color-2;
          letter-spacing: -0.31px;
          line-height: 38px;

          time {
          }

        }

        p {
          //color: #3A5C64;
          letter-spacing: -0.2px;
          line-height: 24px;

        }

        .btn {
          position: absolute;
          top: -34px;
          right: 0;
          width: 150px;
          height: 34px;
          border-radius: 0;
          font-weight: 400;
          font-size: 18px;
          padding: 0;
          line-height: 32px;
          margin: 0;

          &:hover, &:focus, &:active {
            border-color: #fff !important;
          }
        }
      }
    }
  }

  .slick-dots {
    bottom: 30px;
  }
}

.news-single {
  ul + p {
    margin-top: 20px;
  }

  a {
    color: $color-2;

    &:hover, &:focus, &:active {
      color: $color-3;
    }
  }

  .news-img-wrap {
    img {
      padding-bottom: 30px;
    }
  }
}

/*==========  BREAKPOINTS  MAX-WIDTH ==========*/

@media only screen and (max-width: 1339px) {

}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/

@media only screen and (max-width: 999px) {

}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/

@media only screen and (max-width: 767px) {

}

/*END BREAKPOINT MAX 767px-------------------------------------------------*/

@media only screen and (max-width: 479px) {
  section.newsslider .slider .slide {
    .newsBox {
      padding: 20px;

      h3 {
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 15px;
      }

      p {
        font-size: 16px;
        line-height: 22px
      }
    }
  }
}

/*END BREAKPOINT MAX 479px-------------------------------------------------*/

@media only screen and (max-width: 374px) {

}

/*END BREAKPOINT MAX 374px-------------------------------------------------*/

@media only screen and (max-width: 320px) {

}

/*END BREAKPOINT MAX 320px-------------------------------------------------*/

/*==========  BREAKPOINTS  MIN-WIDTH ==========*/

@media only screen and (min-width: 320px) {

}

/*END BREAKPOINT MIN 320px-------------------------------------------------*/

@media only screen and (min-width: 375px) {

}

/*END BREAKPOINT MIN 375px-------------------------------------------------*/

@media only screen and (min-width: 480px) {

}

/*END BREAKPOINT MIN 480px-------------------------------------------------*/

@media only screen and (min-width: 768px) {

}

/*END BREAKPOINT MIN 768px-------------------------------------------------*/

@media only screen and (min-width: 1000px) {

}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/

@media only screen and (min-width: $screen-lg) {

}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/