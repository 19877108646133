/*Messe-Unternehmen*/
.messe-unternehmen{
  [class*="col-"]{
    h2, .h2, h3, .h3{
      font-weight: 400;
      letter-spacing: -0.015em;
    }
    .btn {width:100%;}
  }
  figure{
    margin-bottom: 40px;
  }
}
/*==========  BREAKPOINTS  MAX-WIDTH ==========*/


@media only screen and (max-width: 1339px) {
  .messe-unternehmen{
    [class*="col-"]{
      h2, .h2, h3, .h3{
        font-size: 24px;
      }
    }
    figure{
    }
  }
}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/


@media only screen and (max-width: 999px) {
  .messe-unternehmen{
    [class*="col-"]{
      max-width: 420px;
      width: 100%;
      margin: 0 auto;
      margin-bottom: 50px;
      float: none;
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
}
