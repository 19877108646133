/*SECTIONS*/
.bgGreen, .bgGreenBlue {
  background: $sectionBG-4;
  color: #fff;
  p{
    color: #fff;
  }
  h1, .h1, h2, .h2, h3, .h3, h4, .h4, p, p a, li, li a{
    color: #fff;
  }
  p a, li a{
    &:hover, &:focus, &:active {
      color: $color-3;
    }
  }
}
.bgGreenBlue {
  background: $sectionBG-3;
}
.bgGreyLight{
  background: $sectionBG-1;
}
.bgGreyLighter{
  background: $sectionBG-2;
}
section{
  position: relative;
  padding: 51px 0;
  width: 100%;
  overflow-x: hidden;
  &.bgGreen, &.bgGreenBlue, &.bgGreen.textonly, &.bgGreenBlue.textonly{
    color: #fff;
    h1, .h1, h2, .h2, h3, .h3, h4, .h4, p, p a, ul, ol, li, ul li, ol li, ul li a, ol li a{
      color: #fff;
    }
    a{
      text-decoration: underline;
    }
    p a, ul li a, ol li a{
      &:hover, &:focus, &:active {
        color: $color-3;
      }
    }
    ul.checked li:before{
      background-image: url("../Img/icons/hook_white.svg");
    }
  }
}


/*==========  BREAKPOINTS  MAX-WIDTH ==========*/


@media only screen and (max-width: 1339px) {

}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/


@media only screen and (max-width: 999px) {

}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/


@media only screen and (max-width: 767px) {

}

/*END BREAKPOINT MAX 767px-------------------------------------------------*/


@media only screen and (max-width: 479px) {

}

/*END BREAKPOINT MAX 479px-------------------------------------------------*/


@media only screen and (max-width: 374px) {

}

/*END BREAKPOINT MAX 374px-------------------------------------------------*/


@media only screen and (max-width: 320px) {

}

/*END BREAKPOINT MAX 320px-------------------------------------------------*/



/*==========  BREAKPOINTS  MIN-WIDTH ==========*/


@media only screen and (min-width: 320px) {

}

/*END BREAKPOINT MIN 320px-------------------------------------------------*/


@media only screen and (min-width: 375px) {

}

/*END BREAKPOINT MIN 375px-------------------------------------------------*/


@media only screen and (min-width: 480px) {

}

/*END BREAKPOINT MIN 480px-------------------------------------------------*/

@media only screen and (min-width: 768px) {

}

/*END BREAKPOINT MIN 768px-------------------------------------------------*/


@media only screen and (min-width: 1000px) {

}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/

@media only screen and (min-width: $screen-lg) {

}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/