/* open-sans-300 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('../webfont/open-sans-v18-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../webfont/open-sans-v18-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../webfont/open-sans-v18-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../webfont/open-sans-v18-latin-300.woff') format('woff'), /* Modern Browsers */
       url('../webfont/open-sans-v18-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../webfont/open-sans-v18-latin-300.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-300italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url('../webfont/open-sans-v18-latin-300italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../webfont/open-sans-v18-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../webfont/open-sans-v18-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../webfont/open-sans-v18-latin-300italic.woff') format('woff'), /* Modern Browsers */
       url('../webfont/open-sans-v18-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../webfont/open-sans-v18-latin-300italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../webfont/open-sans-v18-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../webfont/open-sans-v18-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../webfont/open-sans-v18-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../webfont/open-sans-v18-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../webfont/open-sans-v18-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../webfont/open-sans-v18-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('../webfont/open-sans-v18-latin-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../webfont/open-sans-v18-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../webfont/open-sans-v18-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../webfont/open-sans-v18-latin-italic.woff') format('woff'), /* Modern Browsers */
       url('../webfont/open-sans-v18-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../webfont/open-sans-v18-latin-italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-600 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('../webfont/open-sans-v18-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../webfont/open-sans-v18-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../webfont/open-sans-v18-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../webfont/open-sans-v18-latin-600.woff') format('woff'), /* Modern Browsers */
       url('../webfont/open-sans-v18-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../webfont/open-sans-v18-latin-600.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-600italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url('../webfont/open-sans-v18-latin-600italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../webfont/open-sans-v18-latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../webfont/open-sans-v18-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../webfont/open-sans-v18-latin-600italic.woff') format('woff'), /* Modern Browsers */
       url('../webfont/open-sans-v18-latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../webfont/open-sans-v18-latin-600italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../webfont/open-sans-v18-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../webfont/open-sans-v18-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../webfont/open-sans-v18-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../webfont/open-sans-v18-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../webfont/open-sans-v18-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../webfont/open-sans-v18-latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-700italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url('../webfont/open-sans-v18-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../webfont/open-sans-v18-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../webfont/open-sans-v18-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../webfont/open-sans-v18-latin-700italic.woff') format('woff'), /* Modern Browsers */
       url('../webfont/open-sans-v18-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../webfont/open-sans-v18-latin-700italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-800 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url('../webfont/open-sans-v18-latin-800.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../webfont/open-sans-v18-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../webfont/open-sans-v18-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
       url('../webfont/open-sans-v18-latin-800.woff') format('woff'), /* Modern Browsers */
       url('../webfont/open-sans-v18-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../webfont/open-sans-v18-latin-800.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-800italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url('../webfont/open-sans-v18-latin-800italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../webfont/open-sans-v18-latin-800italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../webfont/open-sans-v18-latin-800italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../webfont/open-sans-v18-latin-800italic.woff') format('woff'), /* Modern Browsers */
       url('../webfont/open-sans-v18-latin-800italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../webfont/open-sans-v18-latin-800italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}
