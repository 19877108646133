section.allInOne-solution {
  text-align: center;
  padding: 102px 0;
  p {
    text-align: left;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    color: #ffffff;
    letter-spacing: -0.31px;
    margin: 0 auto 75px auto;
    width: 100%;
  }
  h2,
  .h2 {
    font-weight: 400;
    font-size: 36px;
    letter-spacing: -0.51px;
    line-height: 49px;
  }
  figure {
    display: block;
    height: 240px;
    width: auto;
    img {
      height: 100%;
      width: auto;
      margin: 0 auto;
    }
  }
}

/*VORLAGE*/

/*==========  BREAKPOINTS  MAX-WIDTH ==========*/

@media only screen and (max-width: 1339px) {
  section.allInOne-solution {
    p {
      width: 92%;
    }
    h2,
    .h2 {
      font-size: 32px;
    }
    figure {
      height: 160px;
    }
  }
}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/

@media only screen and (max-width: 999px) {
  section.allInOne-solution {
    p {
      width: 100%;
    }
    h2,
    .h2 {
      font-size: 24px;
    }
    figure {
      height: 115px;
    }
  }
}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/

@media only screen and (max-width: 767px) {
  section.allInOne-solution {
    padding: 51px 0;
    p {
      font-size: 14px;
      letter-spacing: -0.22px;
      line-height: 19px;
      margin-bottom: 40px;
    }
    h2,
    .h2 {
      font-size: 28px;
    }
    figure {
      height: 170px;
    }
    .btn {
      margin-left: calc(50% - 100px);
      margin-right: calc(50% - 100px);
      margin-bottom: 51px;
    }
  }
}

/*END BREAKPOINT MAX 767px-------------------------------------------------*/

@media only screen and (max-width: 479px) {
  section.allInOne-solution figure img {
    height: 100%;
    width: 100%;
    margin: 0;
    object-fit: contain;
  }
}

/*END BREAKPOINT MAX 479px-------------------------------------------------*/

@media only screen and (max-width: 374px) {
}

/*END BREAKPOINT MAX 374px-------------------------------------------------*/

@media only screen and (max-width: 320px) {
}

/*END BREAKPOINT MAX 320px-------------------------------------------------*/

/*==========  BREAKPOINTS  MIN-WIDTH ==========*/

@media only screen and (min-width: 320px) {
}

/*END BREAKPOINT MIN 320px-------------------------------------------------*/

@media only screen and (min-width: 375px) {
}

/*END BREAKPOINT MIN 375px-------------------------------------------------*/

@media only screen and (min-width: 480px) {
}

/*END BREAKPOINT MIN 480px-------------------------------------------------*/

@media only screen and (min-width: 768px) {
}

/*END BREAKPOINT MIN 768px-------------------------------------------------*/

@media only screen and (min-width: 1000px) {
}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/

@media only screen and (min-width: $screen-lg) {
}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/
