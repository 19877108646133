/*features-teaser*/

section.features-teaser {
  [class*="col-"] {
    vertical-align: bottom !important;
  }
  h1 {
    text-align: center;
    font-size: 36px;
    font-weight: 600;
    color: $color-3;
    margin-bottom: 80px;
    letter-spacing: -0.4px;
    line-height: 49px;
  }
  a {
    display: inline-block;
    text-decoration: none;
    transition: $t-medium;
    .icon {
      padding: 0 30px;
      margin: 0 0 15px 0;
      text-align: center;
      transition: $t-fast;
      img {
        width: 100%;
        max-width: 100px;
        height: auto;
        max-height: 100px;
        display: inline-block;
      }
    }
    h2, .h2, p {
      text-decoration: none;
    }
    &:hover, &:focus {
      transform:scale(1.075);
    }
  }
}

/*==========  BREAKPOINTS  MAX-WIDTH ==========*/

@media only screen and (max-width: 1339px) {
  section.features-teaser {
    [class*="col-"] {
      vertical-align: top !important;
    }
  }
}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/

@media only screen and (max-width: 999px) {

}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/

@media only screen and (max-width: 767px) {
  section.features-teaser {
    [class*="col-"] {
      margin-bottom: 60px;
    }
  }
}

/*END BREAKPOINT MAX 767px-------------------------------------------------*/

@media only screen and (max-width: 479px) {
  section.features-teaser h1 {
    font-size: 32px;
    line-height: 44px;
  }
}

/*END BREAKPOINT MAX 479px-------------------------------------------------*/

@media only screen and (max-width: 374px) {

}

/*END BREAKPOINT MAX 374px-------------------------------------------------*/

@media only screen and (max-width: 320px) {

}

/*END BREAKPOINT MAX 320px-------------------------------------------------*/

/*==========  BREAKPOINTS  MIN-WIDTH ==========*/

@media only screen and (min-width: 320px) {

}

/*END BREAKPOINT MIN 320px-------------------------------------------------*/

@media only screen and (min-width: 375px) {

}

/*END BREAKPOINT MIN 375px-------------------------------------------------*/

@media only screen and (min-width: 480px) {

}

/*END BREAKPOINT MIN 480px-------------------------------------------------*/

@media only screen and (min-width: 768px) {

}

/*END BREAKPOINT MIN 768px-------------------------------------------------*/

@media only screen and (min-width: 1000px) {

}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/

@media only screen and (min-width: $screen-lg) {

}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/