

section.text-image{
  padding: 0;
  article{
    padding: 51px 0;
    .col-text{
      margin-left: 8.33333%;
    }
    &.imgRight{
      .col-image{
        float: right;
      }
      .col-text{
        margin-right: 8.33333%;
        margin-left: 0;
      }
    }
    &.textGrey{
      //color: #a1a1a1;
      h1, .h1, h2, .h2, h3, .h3, h4, .h4{
        //color: #535353;
      }
      p, p a{
        //color: #a1a1a1;
      }
      p a{
        &:hover, &:focus, &:active {
          color: $color-3;
        }
      }
      .btn {
        &:before {background-color: $color-2;}
       span {color:#fff;}
        &:hover span {color:#fff;}
        &:hover:before {background-color: $color-2;}
      }
    }
    .btn {
      background-color: $color-2;
      border-color: $color-2;
      &:before{
        background: transparentize(#fff, .85);
      }
      span{
        color: #fff;
      }

    }
    &.bgGreen{
      .btn{
        background-color: #fff;
        border-color: #fff;
        &:before{
          background: rgba(var(--color-2), .25);
        }
        span{
          color: $color-2;
        }
        &:hover, &:focus{
          border-color: rgba(var(--color-2), .25);
          span{color: $color-2;}
        }
      }
    }
  }
}
/*==========  BREAKPOINTS  MAX-WIDTH ==========*/


@media only screen and (max-width: 1339px) {

}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/


@media only screen and (max-width: 999px) {
  section.text-image{
    padding: 0;
    article{
      padding: 51px 0;
      .col-text{
        margin-left: 0;
        width: 50%;
      }
      &.imgRight{
        .col-image{
          float: right;
        }
        .col-text{
          margin-right: 0;
          margin-left: 0;
          width: 50%;
        }
      }
    }
  }
}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/


@media only screen and (max-width: 767px) {
  section.text-image{
    article{
      .col-text{
        margin-top: 25px;
        float: none;
        width: 100%;
      }
      &.imgRight{
        .col-image{
          float: none;
          width: 100%;
        }
        .col-text{
          float: none;
          width: 100%;
        }
      }
    }
  }
}

/*END BREAKPOINT MAX 767px-------------------------------------------------*/


@media only screen and (max-width: 479px) {

}

/*END BREAKPOINT MAX 479px-------------------------------------------------*/


@media only screen and (max-width: 374px) {

}

/*END BREAKPOINT MAX 374px-------------------------------------------------*/


@media only screen and (max-width: 320px) {

}

/*END BREAKPOINT MAX 320px-------------------------------------------------*/



/*==========  BREAKPOINTS  MIN-WIDTH ==========*/


@media only screen and (min-width: 320px) {

}

/*END BREAKPOINT MIN 320px-------------------------------------------------*/


@media only screen and (min-width: 375px) {

}

/*END BREAKPOINT MIN 375px-------------------------------------------------*/


@media only screen and (min-width: 480px) {

}

/*END BREAKPOINT MIN 480px-------------------------------------------------*/

@media only screen and (min-width: 768px) {

}

/*END BREAKPOINT MIN 768px-------------------------------------------------*/


@media only screen and (min-width: 1000px) {

}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/

@media only screen and (min-width: $screen-lg) {

}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/