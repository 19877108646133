.heroCTA {
  padding-bottom: 0;
  figure {
    width: 100%;
    max-width: 354px;
    display: inline-block;
    vertical-align: middle;
  }
  .cta {
    display: inline-block;
    margin-left: 100px;
    vertical-align: middle;
    blockquote {
      font-weight: 400;
      line-height:49px;
      color: $color-2;
      font-size: 36px;
      letter-spacing: -0.51px;
    }
    .btn{min-width: 420px; max-width: 100%;}
  }
}

/*==========  BREAKPOINTS  MAX-WIDTH ==========*/

@media only screen and (max-width: 1339px) {
  .heroCTA {
    figure {
      max-width: 300px;
    }
    .cta {
      margin-left: 50px;
      width: calc(100% - 360px);
      blockquote {
      }
    }
  }
}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/

@media only screen and (max-width: 999px) {
  .heroCTA {
    figure {
      max-width: 275px;
    }
    .cta {
      width: calc(100% - 300px);
      margin-left: 0;
      blockquote {
        font-weight: 400;
        font-size: 24px;
        line-height: 33px;
        color: $color-2;
        letter-spacing: -0.34px;
      }
      .btn{min-width: 270px;}
    }
  }
}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/

@media only screen and (max-width: 767px) {
  .heroCTA {
    padding-top: 25px;
    figure {
      max-width: 250px;
      margin-left: -94px;
    }
    .cta {
      width: calc(100% - 165px);
      blockquote {
        font-size: 16px;
        line-height: 22px;
        letter-spacing: -0.2px;
        br{display: none;}
      }
      .btn{min-width: 185px; font-size: 12px;}
    }
  }
}

/*END BREAKPOINT MAX 767px-------------------------------------------------*/

@media only screen and (max-width: 479px) {

}

/*END BREAKPOINT MAX 479px-------------------------------------------------*/

@media only screen and (max-width: 413px) {
  .heroCTA {
    figure {
      max-width: 200px;
      margin-left: -75px;
    }
    .cta {
    width:calc(100% - 133px);
      blockquote {
      }
      .btn{min-width: 0; font-size: 12px;}
    }
  }
}

/*END BREAKPOINT MAX 374px-------------------------------------------------*/

@media only screen and (max-width: 320px) {

}

/*END BREAKPOINT MAX 320px-------------------------------------------------*/

/*==========  BREAKPOINTS  MIN-WIDTH ==========*/

@media only screen and (min-width: 320px) {

}

/*END BREAKPOINT MIN 320px-------------------------------------------------*/

@media only screen and (min-width: 375px) {

}

/*END BREAKPOINT MIN 375px-------------------------------------------------*/

@media only screen and (min-width: 480px) {

}

/*END BREAKPOINT MIN 480px-------------------------------------------------*/

@media only screen and (min-width: 768px) {

}

/*END BREAKPOINT MIN 768px-------------------------------------------------*/

@media only screen and (min-width: 1000px) {

}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/

@media only screen and (min-width: $screen-lg) {

}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/