/*BUTTONS*/

.btn {
  font-weight: 600;
  font-size: 18px;
  letter-spacing: -0.31px;
  display: inline-block;
  border: 1px solid $color-2;
  background: #fff;
  padding: 13px 25px;
  text-align: center;
  min-width: 200px;
  cursor: pointer;
  text-decoration: none !important;
  position: relative;
  overflow: hidden;
  z-index: 10;
  margin-top:30px;
  &.btn-block{
    display: block;
    width: 100%;
  }
  
  span {
    position: relative;
    z-index: 2;
    transition: $t-medium;
    color: $color-2;
    text-shadow: none !important;
  }
  &:before {
    transition: $t-fast;
    position: absolute;
    z-index: 1;
    display: block;
    content: "";
    background-color: rgba(var(--color-2), .25);
    width: 0;
    padding-bottom: 0;
    left: 50%;
    top:50%;
    transform: translate3D(-50%, -50%, 0);
    border-radius:50%;
  }
  &:hover, &:focus{
    border-color: rgba(var(--color-2), .25);
    &:before{
      width: 110%;
      padding-bottom: 110%;
    }
  }


  &.green, .bgGreen &, .bgGreenBlue & {
    background-color: $color-2;
    border-color: $color-2;
    &:before{
      background: transparentize(#fff, .85);
    }
    span{
      color: #fff;
    }

    &:hover, &:focus{
      border-color:$color-2;
      span{color: #fff;}
    }
  }
  .bgGreen &, .bgGreenBlue &{
    &:hover, &:focus{
      border-color:#fff;
    }
  }
  &.round{
    border-radius: 10px;
  }
  &.green-light{
    background-color: $color-2;
    border-color: $color-2;
    &:before{
      background: rgba(255,255,255,0.15);
    }
    span{
      color: #fff;
    }
    &:hover, &:focus{
      span{color: #fff}
    }

  }
  &.white {
    background-color: #fff;
    border-color: #fff;
    &:before{
      background: $color-3;
    }
    span{
      color: $color-3;
    }
    &:hover, &:focus{
      border-color:$color-3;
      span{color: #fff;}
    }
  }
  &.green-dark {
    background-color: $color-3;
    border-color: $color-3;
    &:before{
      background: #fff;
    }
    span{
      color: #fff;
    }
    &:hover, &:focus{
      border-color:$color-3;
      span{color: $color-3;}
    }
  }
  &.green-blue {
    background-color: $color-4;
    border-color: $color-4;
    &:before{
      background: #fff;
    }
    span{
      color: #fff;
    }
    &:hover, &:focus{
      border-color:$color-4;
      span{color: $color-4;}
    }
  }
}
.bgGreen, .bgGreenBlue{
  .btn{
    border-color: #fff;
    background: #fff;
    &:before{
      background: $color-2;
    }
    span{
      color: $color-2;
    }
    &:hover, &:focus{
      span{color: #fff;}
    }
    &.green{
      border-color: #fff;
      background: #fff;
      &:before{
        background: $color-2;
      }
      span{
        color: $color-2;
      }
      &:hover, &:focus{
        span{color: #fff;}
      }
    }
    &.green-light{
      border-color: #fff;
      &:before{
        background: #fff;
      }
      span{
        color: #fff;
      }
      &:hover, &:focus{
        span{color: $color-2;}
      }
    }
  }
}
a.link{
  text-decoration: none;
  margin: 20px 0;
  color: $color-3;
  display: inline-block;
  transition: $t-fast;
  &:before, &:after{
    transition: $t-fast;
  }
  &:before{
    content:"> ";
    display: inline;
  }
  &:after{
    content:" >";
    display: inline;
    opacity: 0;
  }
  &:hover, &:focus, &:active{
    color: $color-2;
    &:before{
      opacity: 0;
    }
    &:after{
      opacity: 1;
    }
  }
}

/*==========  BREAKPOINTS  MAX-WIDTH ==========*/

@media only screen and (max-width: 1339px) {

}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/

@media only screen and (max-width: 999px) {

}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/

@media only screen and (max-width: 767px) {

}

/*END BREAKPOINT MAX 767px-------------------------------------------------*/

@media only screen and (max-width: 479px) {
  .btn{
    min-width: 150px;
    font-size: 14px;
    padding: 10px 15px;
  }
}

/*END BREAKPOINT MAX 479px-------------------------------------------------*/

@media only screen and (max-width: 374px) {

}

/*END BREAKPOINT MAX 374px-------------------------------------------------*/

@media only screen and (max-width: 320px) {

}

/*END BREAKPOINT MAX 320px-------------------------------------------------*/

/*==========  BREAKPOINTS  MIN-WIDTH ==========*/

@media only screen and (min-width: 320px) {

}

/*END BREAKPOINT MIN 320px-------------------------------------------------*/

@media only screen and (min-width: 375px) {

}

/*END BREAKPOINT MIN 375px-------------------------------------------------*/

@media only screen and (min-width: 480px) {

}

/*END BREAKPOINT MIN 480px-------------------------------------------------*/

@media only screen and (min-width: 768px) {

}

/*END BREAKPOINT MIN 768px-------------------------------------------------*/

@media only screen and (min-width: 1000px) {

}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/

@media only screen and (min-width: $screen-lg) {

}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/