.bs_tabs{
  overflow: visible;
  padding-top: 36px;
  nav{
    position: sticky;
    top: 0;
    z-index: 100;
  }
  h2, .h2{
    color: $color-2;
  }
  p, ul, li, a{
    //color: $grey;
  }
  .bgGreen{
    h2, .h2{
      color: #fff;
    }
  }
}

.nav-tabs{
  text-align: center;
  border: none;
  padding: 15px 0;
  background-color: #fff;
  margin: 0 0 36px 0;

> li {
    border:none;
    margin: 0 18px;
    display: inline-block;
    float: none;
    font-size: $fontsize-base;
    > a{
      text-decoration: none;
      padding: 0;
      color: $bs-tabs;
      border:none;
      border-bottom: 5px solid transparent;
    }
    &.active{
      > a{
        font-weight: 600;
        color: $bs-tabs-active;
        border:none;
        border-bottom: 5px solid $color-2;
      }
    }
  }
  > li, > li.active{
    a{
      &:hover, &:focus, &:active{
        background: none;
        border:none;
        border-bottom: 5px solid $color-2;
        color: $bs-tabs-active;
      }
    }
  }
}

