section.basicInfo{
    figure{
      display: inline-block;
      vertical-align: top;
      width: 120px;
    }
  .info{
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 125px);
    padding-left: 20px;
    margin-top: 7px;
    h2, .h2{
      font-size: 24px;
      line-height: 33px;
      letter-spacing: -0.23px;
      margin: 0 0 2px 0;
    }
    p{
      line-height: 24px;
    }
  }
}

/*==========  BREAKPOINTS  MAX-WIDTH ==========*/


@media only screen and (max-width: 1339px) {
  section.basicInfo {
    text-align: center;
    h1, .h1{
      margin-bottom: 51px;
    }
    figure {
      display: inline-block;
      vertical-align: top;
      width: 120px;
    }
    .info {
      display: block;
      width: 100%;
      padding-left: 0;
      margin-top: 15px;
      h2, .h2 {
      }
      p {
      }
    }
  }
}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/


@media only screen and (max-width: 999px) {
  section.basicInfo {
    figure {
    }
    .info {
      h2, .h2 {
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.31px;
      }
      p {
        font-size: 14px;
        line-height: 19px;
        letter-spacing: -0.23px;
      }
    }
  }
}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/


@media only screen and (max-width: 767px) {
  section.basicInfo {
    figure {
      width: 100%;
      max-width: 250px;
      margin-bottom: 20px;
    }
    .info {
      h2, .h2{
        font-size: 24px;
        line-height: 33px;
        letter-spacing: -0.23px;
        margin: 0 0 2px 0;
      }
      p {
        display: inline-block;
        width: 100%;
        max-width: 250px;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.2px;
        margin-bottom: 45px;
      }
    }
  }
}

/*END BREAKPOINT MAX 767px-------------------------------------------------*/


@media only screen and (max-width: 479px) {

}

/*END BREAKPOINT MAX 479px-------------------------------------------------*/


@media only screen and (max-width: 374px) {

}

/*END BREAKPOINT MAX 374px-------------------------------------------------*/


@media only screen and (max-width: 320px) {

}

/*END BREAKPOINT MAX 320px-------------------------------------------------*/



/*==========  BREAKPOINTS  MIN-WIDTH ==========*/


@media only screen and (min-width: 320px) {

}

/*END BREAKPOINT MIN 320px-------------------------------------------------*/


@media only screen and (min-width: 375px) {

}

/*END BREAKPOINT MIN 375px-------------------------------------------------*/


@media only screen and (min-width: 480px) {

}

/*END BREAKPOINT MIN 480px-------------------------------------------------*/

@media only screen and (min-width: 768px) {

}

/*END BREAKPOINT MIN 768px-------------------------------------------------*/


@media only screen and (min-width: 1000px) {

}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/

@media only screen and (min-width: $screen-lg) {

}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/