.priceBox {
  h1, .h1 {
    text-align: left;
  }
  h2, .h2 {
    text-align: center;
    color: $color-2;
  }
  h3, .h3 {
    font-weight: 400;
    font-size: 24px;
    color: $color-2;
    letter-spacing: -0.17px;
    line-height: 33px;
    margin: 15px 0;
  }
  .items {
    border: 1px solid $color-2;
    text-align: left;
  }
  .asterisk {
    font-size: 14px;
    font-weight: 400;
    color: $color-2;
    line-height: 19px;
    letter-spacing: -0.31px;
    margin-top: 25px;
  }
  ul {
    li {
      font-size: 18px;
      color: $grey;
      letter-spacing: -0.26px;
      line-height: 44px;
      padding-left: 8px;
      list-style-image: url("../Img/bullet_green.svg");
    }
  }
  .box {
    width: 50%;
    float: left;
    padding: 35px 115px;
    &.b2 {
      background: rgba(var(--color-2), .1);
      .btn {
        background: transparent;
        max-width: none;
        width: 100%;
        margin: 0 0 15px 0;
        &:before, &:after {
          display: none;
        }
      }
    }
  }
}

/*==========  BREAKPOINTS  MAX-WIDTH ==========*/

@media only screen and (max-width: 1339px) {
  .priceBox {
    .box {
      width: 50%;
      float: left;
      padding: 35px 80px;
    }
  }
}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/

@media only screen and (max-width: 999px) {
  .priceBox {
    .box {
      padding: 25px;
    }
  }
}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/

@media only screen and (max-width: 767px) {
  .priceBox {
    ul li {
      font-size: 16px;
      line-height: 38px;
    }
    .box {
      &.b2 .btn {
        font-size: 16px;
      }
    }
  }
}

/*END BREAKPOINT MAX 767px-------------------------------------------------*/

@media only screen and (max-width: 679px) {
  .priceBox {
    ul li {
    }
    .box {
      width: 100%;
      height: auto !important;
      &.b2 .btn {
        font-size: 16px;
      }
    }
  }
}

/*END BREAKPOINT MAX 479px-------------------------------------------------*/

@media only screen and (max-width: 374px) {

}

/*END BREAKPOINT MAX 374px-------------------------------------------------*/

@media only screen and (max-width: 350px) {
  .priceBox .box.b2 .btn {
    font-size: 14px;
  }
}

/*END BREAKPOINT MAX 320px-------------------------------------------------*/

/*==========  BREAKPOINTS  MIN-WIDTH ==========*/

@media only screen and (min-width: 320px) {

}

/*END BREAKPOINT MIN 320px-------------------------------------------------*/

@media only screen and (min-width: 375px) {

}

/*END BREAKPOINT MIN 375px-------------------------------------------------*/

@media only screen and (min-width: 480px) {

}

/*END BREAKPOINT MIN 480px-------------------------------------------------*/

@media only screen and (min-width: 768px) {

}

/*END BREAKPOINT MIN 768px-------------------------------------------------*/

@media only screen and (min-width: 1000px) {

}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/

@media only screen and (min-width: $screen-lg) {

}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/