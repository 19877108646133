/*TIMELINE - NEWS*/

/*==========  BREAKPOINTS  MAX-WIDTH ==========*/
  .timeline {
    padding-top: 51px;
    padding-bottom: 51px;
    h1{
      text-align: center;
    }
    .items {
      position: relative;
      &:before {
        content: "";
        display: block;
        width: 1px;
        height: 100%;
        background: $color-2;
        position: absolute;
        z-index: 1;
        left: 50%;
        top: 10px;
      }
      .item {
        position: relative;
        z-index: 2;
        [class*="col-"] {
          min-height: 125px;
          &:before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            width: 32px;
            height: 32px;
            background: #fff;
            border: 9px solid $color-2;
            border-radius: 30px;
          }
        }
        time {
          font-weight: 600;
          font-size: 36px;
          line-height: 49px;
          color: $color-2;
          letter-spacing: -0.51px;
        }
        h2, .h2 {
          font-weight: 600;
          font-size: 20px;
          line-height: 27px;
          color: $color-2;
          letter-spacing: -0.57px;
          margin-bottom: 5px;
          margin-top: 0;
        }
        p {
          color: $color-2;
          line-height: 24px;
          font-size: 18px;
        }
        .btn {
          margin-bottom: 15px;
        }
        &:nth-child(odd) {
          text-align: right;
          [class*="col-"] {
            padding-right: 50px;
            &:before {
              right: 0;
              transform: translateX(50%);
            }
          }
        }
        &:nth-child(even) {
          text-align: left;
          [class*="col-"] {
            padding-left: 50px;
            &:before {
              left: 0;
              transform: translateX(-50%);
            }
          }
          [class*="col-"] {
            float: right;
          }
        }
      }
      //END .item
      .btn.green {
        margin-bottom: 0;
        top: 25px;
      }
    }
  }

section.milestones.bgGreen{
  background: $color-2;
  .timeline{
    .items{
      &:before, &:after{
        background: #fff;
      }
      .item{
        time, h2, .h2, p{
          color: #fff;
        }
        h2, .h2{
          display: none;
        }
        time{
          font-weight: 600;
          font-size: 24px;
          letter-spacing: -0.68px;
          line-height: 33px;
        }
        [class*="col-"]{
          &:before{
            background: $color-2;
            border-color: #fff;
          }
        }
      }
    }
  }
}


@media only screen and (max-width: 1339px) {
.timeline{
  .items{
    .item{
      time{
        font-size: 24px;
        line-height: 33px;
        letter-spacing: -0.34px;
      }
      h2, .h2{
        font-size: 16px;
        letter-spacing: -0.46px;
        line-height: 22px;
      }
      p{
        font-size: 14px;
        letter-spacing: -0.2px;
        line-height: 19px;
      }
    }
  }
}
}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/

@media only screen and (max-width: 999px) {

}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/

@media only screen and (max-width: 767px) {
  .timeline {
    .items {
      &:before {
        left: 5px;
      }
      &:after{
        content: "";
        display: block;
        position: absolute;
        left: 5px;
        transform: translateY(10px);
        width: 60px;
        height: 1px;
        background: $color-2;
        bottom:0;
      }
      .item{
        margin-bottom: 50px;
        &:nth-child(even), &:nth-child(odd){
          [class*="col-"]{
            padding: 0 25px 0 60px;
            min-height: 50px;
            &:before{
              left: 10px;
              margin-top: 5px;
              right: auto;
              transform: translateX(0);
              width: 20px;
              height: 20px;
            }
            float: none;
            text-align: left;
          }
        }
        &:last-child{
          margin-bottom: 0;
        }
      }
      .centered{
        text-align: left;
        .btn{
          margin-left: 35px;
          top:28px;
        }
      }
    }
  }
}

/*END BREAKPOINT MAX 767px-------------------------------------------------*/

@media only screen and (max-width: 479px) {

}

/*END BREAKPOINT MAX 479px-------------------------------------------------*/

@media only screen and (max-width: 374px) {

}

/*END BREAKPOINT MAX 374px-------------------------------------------------*/

@media only screen and (max-width: 320px) {

}

/*END BREAKPOINT MAX 320px-------------------------------------------------*/

/*==========  BREAKPOINTS  MIN-WIDTH ==========*/

@media only screen and (min-width: 320px) {

}

/*END BREAKPOINT MIN 320px-------------------------------------------------*/

@media only screen and (min-width: 375px) {

}

/*END BREAKPOINT MIN 375px-------------------------------------------------*/

@media only screen and (min-width: 480px) {

}

/*END BREAKPOINT MIN 480px-------------------------------------------------*/

@media only screen and (min-width: 768px) {

}

/*END BREAKPOINT MIN 768px-------------------------------------------------*/

@media only screen and (min-width: 1000px) {

}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/

@media only screen and (min-width: $screen-lg) {

}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/