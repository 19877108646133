/*Footer*/
footer{
  padding-top: 51px;
  color: #fff;
  background: $footerBG;
  font-weight: 600;
  p, .text, address, ul li, ol li a{
    font-size: 16px;
  }
  a{
    transition: $t-fast;
    color: #fff;
    text-decoration: none;
    &:hover, &:focus, &:active{
      color: $color-2;
    }
  }
  ul{
    margin: 0;
    padding: 0;
    li{
      list-style: none;
      margin-right: 35px;
      display: block;
      text-align: left;
      a{
        display: block;
      }
    }
    &.f-list{
      li.headline{
        font-weight: bold;
        font-size: 16px;
      }
      li{
        font-size: 14px;
      }
    }
  }
  .ce-footermenu{
    [class*="col-"]{
      margin-bottom: 20px;
    }
  }
  .center-block{
    width: 200px;
  }
  nav.footerNav{
    float: left;
    font-size: 18px;
    margin-bottom: 20px;

  }
  .social{
    float: right;
    a{
      display: inline-block;
      width: 32px;
      height: 32px;
      line-height: 29px;
      text-align: center;
      border: 3px solid #fff;
      border-radius: 50%;
      padding: 5px;
      transition: $t-fast;
      cursor: pointer;
      &.twitter{
        padding: 6px;
        margin-right: 12px;
      }
      &:hover, &:focus{
        border-color: $color-2;
      }
    }
  }
  .copyright{
    margin-top: 31px;
    padding: 51px 0 20px;
    background: #000;
    text-align: center;
    font-size: 14px;
  }
  .cr{
    padding-top: 20px;
    margin-top: 31px;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
  }
  img.footerimg{
    width: auto;
    height: auto;
    margin: 0 auto 20px auto;
  }
}


/*==========  BREAKPOINTS  MAX-WIDTH ==========*/


@media only screen and (max-width: 1339px) {

}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/

@media only screen and (max-width: 999px) {
  footer{
    nav.footerNav{
      width: 33.33333%;
      ul{
        li{
          display: block;
          text-align: left;
          a{}
        }
      }
    }
    img.footerimg {
      margin: 0 0 20px;
    }
  }
}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/


@media only screen and (max-width: 680px) {
  footer{

    nav.footerNav{
      width: 50%;
      ul{
        li{
          display: block;
          text-align: left;
          a{}
        }
      }
    }

    .social {
      float: none;
      text-align: right;
    }
    .center-block {
      display: block;
      margin-right: 0;
      margin-left: 0;
      float: right;
      width: 100%;
    }
    img.footerimg {
        margin: 31px 0 20px;
    }
    .cr{
      text-align: left;
    }
  }
}

@media only screen and (max-width: 399px) {
  footer{
    .social a.twitter {
      margin-right: 5px;
    }
    .copyright {
      padding: 20px 0 20px;
    }
  }
}

/*END BREAKPOINT MAX 374px-------------------------------------------------*/


@media only screen and (max-width: 320px) {

}

/*END BREAKPOINT MAX 320px-------------------------------------------------*/



/*==========  BREAKPOINTS  MIN-WIDTH ==========*/


@media only screen and (min-width: 320px) {

}

/*END BREAKPOINT MIN 320px-------------------------------------------------*/


@media only screen and (min-width: 375px) {

}

/*END BREAKPOINT MIN 375px-------------------------------------------------*/


@media only screen and (min-width: 480px) {

}

/*END BREAKPOINT MIN 480px-------------------------------------------------*/

@media only screen and (min-width: 768px) {

}

/*END BREAKPOINT MIN 768px-------------------------------------------------*/


@media only screen and (min-width: 1000px) {

}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/

@media only screen and (min-width: $screen-lg) {

}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/
