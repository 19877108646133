@font-face {
    font-family: "icons";
    src: url("../p4b/iconfont/icons.woff2?vadq2n") format("woff2"),
        url("../p4b/iconfont/icons.ttf?vadq2n") format("truetype"),
        url("../p4b/iconfont/icons.woff?vadq2n") format("woff"),
        url("../p4b/iconfont/icons.svg?vadq2n#icons") format("svg");
    font-weight: normal !important;
    font-style: normal !important;
    font-display: block;
}
$chevron: "\e900";
$check: "\e901";
$facebook: "\e902";
$linkedin: "\e903";
$twitter: "\e904";
$xing: "\e905";
.icon {
    font-family: "icons";
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    &.chevron:before {
        content: $chevron;
    }
    &.check:before {
        content: $check;
    }
    &.facebook:before {
        content: $facebook;
    }
    &.linkedin:before {
        content: $linkedin;
    }
    &.twitter:before {
        content: $twitter;
    }
    &.xing:before {
        content: $xing;
    }
}

