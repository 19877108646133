/*gruende-unternehmen*/

.gruende-unternehmen {
  padding-top: 120px;
  h1 {
    color: $color-2;
  }
  .row.centered {
    [class*="col-"] {
      text-align: left;
    }
  }
  .image {
    img {
      transform: translate3D(13.6%, -50%, 0) scale(1.25);
      transform-origin: center top;
    }
  }
}

/*==========  BREAKPOINTS  MAX-WIDTH ==========*/

@media only screen and (max-width: 1339px) {
  .gruende-unternehmen {
    padding-top: 80px;
    h1 {
    }
    .row.centered {
      [class*="col-"] {
      }
    }
    .image {
      img {
        transform: translate3D(0, 0, 0) scale(1);
        transform-origin: center top;
      }
    }
  }
}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/

@media only screen and (max-width: 999px) {
  .gruende-unternehmen {
    h1 {
    }
    .row.centered {
      [class*="col-"] {
      }
    }
    .image {
      margin: 50px 0;
      img {
        transform: translate3D(0, 0, 0) scale(1);
        transform-origin: center top;
      }
    }
  }
}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/

@media only screen and (max-width: 767px) {
  .gruende-unternehmen {
    padding-top: 0;
    h1 {
    }
    .row.centered {
      [class*="col-"] {
      }
    }
    .image {
      img {

      }
    }
  }
}

/*END BREAKPOINT MAX 767px-------------------------------------------------*/

@media only screen and (max-width: 479px) {

}

/*END BREAKPOINT MAX 479px-------------------------------------------------*/

@media only screen and (max-width: 374px) {

}

/*END BREAKPOINT MAX 374px-------------------------------------------------*/

@media only screen and (max-width: 320px) {

}

/*END BREAKPOINT MAX 320px-------------------------------------------------*/

/*==========  BREAKPOINTS  MIN-WIDTH ==========*/

@media only screen and (min-width: 320px) {

}

/*END BREAKPOINT MIN 320px-------------------------------------------------*/

@media only screen and (min-width: 375px) {

}

/*END BREAKPOINT MIN 375px-------------------------------------------------*/

@media only screen and (min-width: 480px) {

}

/*END BREAKPOINT MIN 480px-------------------------------------------------*/

@media only screen and (min-width: 768px) {

}

/*END BREAKPOINT MIN 768px-------------------------------------------------*/

@media only screen and (min-width: 1000px) {

}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/

@media only screen and (min-width: $screen-lg) {

}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/