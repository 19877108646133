/*VORLAGE*/
.uh-medien {
  h1, .h1{
    text-align: left;
  }
  h1, .h1, h2, .h2 {
    color: $grey;
    font-size: 24px;
    line-height: 33px;
    letter-spacing: -0.27px;
    margin-bottom: 20px;
  }
  .col-lg-20{
    display: block !important;
    margin: 0 auto !important;
  }
  .slick-dots {
    position: relative;
    margin-top: 50px;
    li {
      border: 1px solid $color-2;
    }
  }
  .items {
    background: #fff;
    padding: 51px 0;
    .item {
      padding: 0 25px;
      text-align: center;
      figure {
        display: inline-block;
        max-width: 256px;
        width: 100%;
        padding: 40px;
        border-radius: 50%;
        border: 1px solid $color-2;
        overflow: hidden;
        margin-bottom: 25px;
      }
      h2 {
        margin-bottom: 5px;
      }
      p {
        color: $grey;
      }
    }
  }
}

/*==========  BREAKPOINTS  MAX-WIDTH ==========*/

@media only screen and (max-width: 1339px) {

}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/

@media only screen and (max-width: 999px) {

}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/

@media only screen and (max-width: 767px) {

}

/*END BREAKPOINT MAX 767px-------------------------------------------------*/

@media only screen and (max-width: 479px) {

}

/*END BREAKPOINT MAX 479px-------------------------------------------------*/

@media only screen and (max-width: 374px) {

}

/*END BREAKPOINT MAX 374px-------------------------------------------------*/

@media only screen and (max-width: 320px) {

}

/*END BREAKPOINT MAX 320px-------------------------------------------------*/

/*==========  BREAKPOINTS  MIN-WIDTH ==========*/

@media only screen and (min-width: 320px) {

}

/*END BREAKPOINT MIN 320px-------------------------------------------------*/

@media only screen and (min-width: 375px) {

}

/*END BREAKPOINT MIN 375px-------------------------------------------------*/

@media only screen and (min-width: 480px) {

}

/*END BREAKPOINT MIN 480px-------------------------------------------------*/

@media only screen and (min-width: 768px) {

}

/*END BREAKPOINT MIN 768px-------------------------------------------------*/

@media only screen and (min-width: 1000px) {

}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/

@media only screen and (min-width: $screen-lg) {

}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/