/*Team-Trust*/

section.teamTrust {
  .container.items {
    width: 66.66666%;
  }
  figure {
    margin-bottom: 30px;
  }
  p {
    line-height: 22px;
  }
  h1, .h1 {
    font-size: 36px;
    color: #FFFFFF;
    letter-spacing: -0.68px;
    margin-bottom: 50px;
    line-height: 39px;
  }
  h2, .h2 {
    margin-bottom: 10px;
  }
  .item {
    text-align: left;
    margin-bottom: 50px;
  }
}

/*==========  BREAKPOINTS  MAX-WIDTH ==========*/

@media only screen and (max-width: 1339px) {

}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/

@media only screen and (max-width: 999px) {

}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/

@media only screen and (max-width: 767px) {
  section.teamTrust .container.items {
    width: 90%;
    a {
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

/*END BREAKPOINT MAX 767px-------------------------------------------------*/

@media only screen and (max-width: 479px) {

}

/*END BREAKPOINT MAX 479px-------------------------------------------------*/

@media only screen and (max-width: 375px) {
  section.teamTrust .container.items {

  }
}

/*END BREAKPOINT MAX 374px-------------------------------------------------*/

@media only screen and (max-width: 320px) {
  section.teamTrust .container.items {
width: 100%;
  }
}

/*END BREAKPOINT MAX 320px-------------------------------------------------*/

/*==========  BREAKPOINTS  MIN-WIDTH ==========*/

@media only screen and (min-width: 320px) {

}

/*END BREAKPOINT MIN 320px-------------------------------------------------*/

@media only screen and (min-width: 375px) {

}

/*END BREAKPOINT MIN 375px-------------------------------------------------*/

@media only screen and (min-width: 480px) {

}

/*END BREAKPOINT MIN 480px-------------------------------------------------*/

@media only screen and (min-width: 768px) {

}

/*END BREAKPOINT MIN 768px-------------------------------------------------*/

@media only screen and (min-width: 1000px) {

}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/

@media only screen and (min-width: $screen-lg) {

}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/