/*About Unternehmerheld*/
section.about_uh{
  background: $sectionBG-1;
  h2{
  font-size: 24px;
  color: $grey;
  letter-spacing: -0.27px;
    text-align: left;
}
  .row.centered [class*="col-"]{
    vertical-align: top;
  }
  .bgWhite{
    background: #fff;
    padding: 30px;
    display: table;
  }
  .about-gruender{
    .gruenderlogo{
      text-align: center;
      margin-bottom: 20px;
      img{
        display: inline-block;
        max-width: 130px;
        margin-bottom: 12px;
        margin-top: 5px;
      }
    }
    p{
      text-align: left;
      font-size: $fontsize-base;
      //color: #9C9E9F;
      letter-spacing: 0;
      line-height: 24px;
    }
  }

  .the-team{
    .bgWhite{
      display: block;
    }
    .slick-dots {
      bottom: -30px;
      li {
        border: 1px solid $color-2;
      }
    }
    .items{
      margin-top: 35px;
      .slide{
        width: 100%;
        display: block;
        .inner{
          display: block;
        }
      }
      .item{
        margin: 15px 0 20px;
        display: inline-block;
        text-align: center;
        width: calc(33.33333% - 4px);
        .foto{
          display: inline-block;
          border-radius: 50%;
          max-width: 100px;
          overflow: hidden;
          margin-bottom: 20px;
          img{
            width: 100%;
            height: 100%;
          }
        }
        .name{
          font-size: 16px;
          color: $color-2;
          font-weight: 600;
          letter-spacing: 0;
          margin-bottom: 10px;
        }
        p{
          font-size: 15px;
          line-height: 1.4;
          color: $grey;
        }
        p, .name{
          padding: 0 10px;
        }
      }
    }
  }


}

/*==========  BREAKPOINTS  MAX-WIDTH ==========*/


@media only screen and (max-width: 1339px) {
  section.about_uh {
    .the-team {
      .items {
        margin-top: 60px;
        .item {
          width: calc(50% - 4px);
          .foto {
            img {
            }
          }
          .name {
          }
        }
      }
    }
  }
}


/*END BREAKPOINT MAX 991px-------------------------------------------------*/


@media only screen and (max-width: 999px) {

}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/


@media only screen and (max-width: 767px) {
  section.about_uh{
    .bgWhite{
      height: auto !important;
      margin-bottom: 51px;
    }
    .the-team {
      margin: 0;
      display: block !important;
      .items {
        margin-top: 15px;
        margin-bottom: 55px;
        .item {
          width: calc(33.33333% - 4px);
          .foto {
            img {
            }
          }
          .name {
          }
        }
      }
    }
  }
}

/*END BREAKPOINT MAX 767px-------------------------------------------------*/


@media only screen and (max-width: 600px) {
  section.about_uh {
    .the-team {
      .items {
        .item {
          width: calc(50% - 4px);
          .foto {
            img {
            }
          }
          .name {
          }
        }
      }
    }
  }
}

/*END BREAKPOINT MAX 479px-------------------------------------------------*/


@media only screen and (max-width: 374px) {

}

/*END BREAKPOINT MAX 374px-------------------------------------------------*/


@media only screen and (max-width: 320px) {

}

/*END BREAKPOINT MAX 320px-------------------------------------------------*/



/*==========  BREAKPOINTS  MIN-WIDTH ==========*/


@media only screen and (min-width: 320px) {

}

/*END BREAKPOINT MIN 320px-------------------------------------------------*/


@media only screen and (min-width: 375px) {

}

/*END BREAKPOINT MIN 375px-------------------------------------------------*/


@media only screen and (min-width: 480px) {

}

/*END BREAKPOINT MIN 480px-------------------------------------------------*/

@media only screen and (min-width: 768px) {

}

/*END BREAKPOINT MIN 768px-------------------------------------------------*/


@media only screen and (min-width: 1000px) {

}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/

@media only screen and (min-width: $screen-lg) {

}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/