body.p4b {
    font-display: optional;
    * {
        letter-spacing: 0 !important;
    }

    img {
        transition: all 0.75s;
        &:hover,
        &:focus,
        &:active {
            filter: grayscale(0);
        }
    }
    figure {
        figcaption {
            margin-top: 0.5em;
            font-weight: 400;
        }
    }
    section,
    footer {
        transition: opacity 0.5s;
        & .copyright {
            background: transparent;
        }
    }
    header + section {
        margin-top: 0;
    }
    .btn {
        font-size: 24px;
        font-weight: 800;
        text-decoration: none !important;
        &.round {
            border-radius: 0;
        }
        &:hover {
            opacity: 1 !important;
        }
    }
    .btn,
    .btn.green,
    section.text-image article .btn {
        border: none;
        &:before {
            background: rgba(127, 127, 127, 1);
        }
        &:hover {
            span {
                color: #fff;
            }
        }
    }
    .press-social{
        .uh-pressekontakt{
            figure{
                img{
                    border-radius: 50%;
                }
            }
        }
    }
    .news.news-single {
        .article > section {
            .container {
                > .col-md-16,
                .col-md-8 {
                    padding: 0;
                    margin-bottom: 15px;
                }
            }
        }
    }
}
