body, h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, p {
  font-family: $font-family-1;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  line-height: 1;
  margin-top: 0;
  color: $color-2;
  font-weight: 600;
}
section{
  p, li{
    color: $grey;
  }
  &.hero{
    p, li{
      color: #fff;
    }
  }
}

h1, .h1 {
  font-size: 36px;
  color: $color-2;
  font-weight: 600;
  letter-spacing: -0.51px;
  line-height: 1.35;
}

h2, .h2 {
  color: $color-2;
  font-weight: 600;
  font-size: 28px;
  letter-spacing: -0.31px;
  line-height: 1.06;

  span {
    display: block;
  }
}

h1, .h1, h2, .h2 {
  &.icon {
    font-weight: 600;
    font-size: 36px;
    letter-spacing: -0.51px;
    line-height: 42px;
    position: relative;
    padding-left: 100px;
    margin-bottom: 60px;
    font-family: var(--font-family-1);

    img {
      display: block;
      width: 80px;
      height: auto;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

h3, .h3{
    font-size: 22px;
    font-weight: 700;
    color: rgba(var(--grey), 1);
}
h4, .h4, b, strong {
  font-weight: 700 !important;
}
h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  a {
    &:hover, &:focus, &:active {
      color: $color-3
    }
  }
}

a {
  transition: $t-fast;
  p{
    text-decoration: none !important;
  }
  &:hover, &:focus, &:active {
    h1, .h1, h2, .h2 {
    }
  }

  h1, .h1, h2, .h2, p {
    text-decoration: none;
  }
}

p, .text, address, ul li, ol li {
  font-size: $fontsize-base;
  line-height: 1.6128;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.2px;
  b, strong {
    font-weight: 600;
  }
}
ul, ol{
  margin: 0 0 0 20px;
  padding: 0 0 0 21px;
  + .btn{
    margin-top: 21px;
    margin-bottom: 0;
  }
}
p {
  margin: 0 0 20px;
  &:last-of-type {
    margin-bottom: 0;
    + ol, + ul{
      margin-top: 21px;
    }
  }
}
section{
  p, ul li, ol li, .textonly {
    a {
      transition: $t-fast;
      text-decoration: none;
      border-bottom: 1px solid $color-2;
      color: $grey;
      &:hover, &:focus {
        color: $color-2;
      }
    }
  }
}

.text {
  text-align: left;
}

.text-center {
  text-align: center;

  h1, .h1, h2, .h2, h3, .h3, h4, .h4, p {
    text-align: center;
  }

  h1, .h1, h2, .h2 {
  }

  h2, .h2 {
  }

  p:last-child {

  }

  .small {

  }
}

hr {
  margin: 0;
  border: none;
  height: 0;
  border-top: 1px solid $color-2;
  padding: 0;

  &.divider {

  }
}


ul {
  li {
    list-style-type: disc;
    list-style-position: outside;
  }
  &.checked {
    padding-left: 0;
    margin-bottom: 21px;
    + .btn{
      margin-top: 0;
    }
    li {
      position: relative;
      list-style: none;
      padding-left: 1.5em;
      margin-left: 0;
      white-space: normal;
      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        transform: translateY(0.25em);
        background: url("../Img/icons/hook_green.svg") no-repeat center;
        background-size: contain;
        width: .9em;
        height: .9em;
      }
    }
  }
}

blockquote {
  font-size: 36px;
  color: $color-2;
  letter-spacing: -0.51px;
  line-height: 1.35;
  font-weight: 400;
}

h2.intro, .bs_tabs h2.intro, .bs_tabs .h2.intro {
  color: #7b7b7b;
  font-size: 22px;
}

/*==========  BREAKPOINTS  MAX-WIDTH ==========*/
/* Medium Devices, Desktops */
@media only screen and (max-width: 1339px) {
  h1, .h1, h2, .h2 {
    &.icon {
      margin-bottom: 40px;
    }
  }
}


/* Medium Devices, Desktops */
@media only screen and (max-width: 999px) {
  .text-center {
    .small {
      width: 100%;
      margin-left: 0;
    }
  }
  h1, .h1, h2, .h2, h3, .h3 {
    letter-spacing: -0.28px;
    line-height: 1.35;
    &.icon {
      font-size: 24px;
      letter-spacing: -0.34px;
      line-height: 30px;
      padding-left: 75px;
      img {
        width: 60px;
      }
    }
  }
  h1, .h1 {
    font-size: 28px;
  }
  h2, .h2 {
      font-size: 24px;
  }
  h3, .h3 {
      font-size: 20px;
      color: rgba(var(--grey), 1);
  }
  p, p a, ul li, ol li{
      font-size: 16px;
  }
  blockquote {
    font-size: 24px;
    letter-spacing: -0.34px;
    line-height: 33px;
  }
}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/

/* Small Devices, Tablets */
@media only screen and (max-width: 767px) {
  h1, .h1, h2, .h2 {
    br {
      display: none;
    }
    &.icon {
      font-size: 20px;
      letter-spacing: -0.28px;
      line-height: 22px;
      img {
        width: 60px;
      }
    }
  }
  blockquote {
    font-size: 14px;
    letter-spacing: -0.2px;
    line-height: 19px;
  }
}

/*END BREAKPOINT MAX 767px-------------------------------------------------*/

/* Extra Small Devices, Phones */
@media only screen and (max-width: 479px) {
  h1, .h1, h2, .h2 {
    hyphens: auto;
  }
  h2, .h2 {
    hyphens: auto;

    br {
      display: none;
    }
  }
}
