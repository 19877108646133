/*HEADER & MAINNAV*/

header {
  position: relative;
  width: 100%;
  z-index: 1000;
  padding-top: 19px;
  padding-bottom: 10px;
  top: 0;
  background: rgba(var(--color-4), .6);
  transition: $t-medium;
  border-bottom: 1px solid $color-2;
  //overflow: hidden;
  .notSticky &{

    position: relative !important;
  }
  &.sticky {
    background: #fff;
    border-bottom-color: $color-2;
    padding-top: 19px;
    position: fixed;
    a {
      color: $color-2;
    }
    ul:not(.subnav){
      li {
        a {
          color: $color-2;
          &:hover, &:focus, &:active {
            color: $color-4;
            &:after {
              background: $color-2;
            }
          }
        }

        &.active{
          a {
            color: $color-3 !important;
            &:after {
              background: $color-3 !important;
            }
          }
          &.sub.subnav-open{
            ul.subnav{
              li.inactive{
                a{
                  color: #fff !important;
                }
              }
            }
          }
        }
      }
    }

    .hamburger {
      .burgerFill {
        fill: $color-4 !important;
      }
      .navOpen & {
        .burgerFill {
          fill: #fff !important;
        }
      }
    }
    .reg .btn.green {
      &:hover, &:focus {
        border-color: rgb(232, 232, 232);
        background: #e8e8e8;
        &:before {
          background: #e8e8e8;
        }
        span {
          color: $grey;
        }
      }
    }
  }
  a {
    transition: $t-fast;
    color: #fff;
    font-size: 16px;
    line-height: 1;
    font-weight: 600;
    text-decoration: none;
  }
  .logo {
    float: left;
    width: 225px;
    transform: translateY(-3px);
    margin-right: 5px;
    position: relative;
    z-index: 999;
    a {
      display: block;
    }
    svg {
      width: 100%;
    }
  }
  nav.mainNav {
    float: left;
    ul {
      margin: 0 30px 0 0;
      padding: 0;
    }
    li {
      display: inline-block;
      margin: 0 0 0 22px;
      position: relative;
      a {
        padding: 8px 8px;
        display: block;
        position: relative;
        z-index: 1;
        span {
          position: relative;
          z-index: 2;
        }
        &:after {
          content: "";
          display: block;
          height: 5px;
          width: 100%;
          position: absolute;
          z-index: 1;
          background: #fff;
          transition: $t-fast;
          left: 50%;
          transform: translateX(-50%);
          bottom: -23px;
          opacity: 0;
        }
        &:hover, &:focus, &:active {
          &:after {
            opacity: 1;
          }
        }
      }
      &.active {
        a {
          color: #fff;
          &:after {
            background: #fff;
            animation: none;
            opacity: 1;
          }
        }
      }
    }
  }
  .hamburger {
    display: none;
  }
  .reg {
    float: right;
    a {
      display: inline-block;
      vertical-align: middle;
      padding: 8px 0;
    }
    .btn {
      min-width: 0;
      padding: 8px 20px;
      border: none;
      margin-left: 35px;
      margin-top: 0;
    }
    > a:first-child:hover {
      text-decoration: underline;
    }
    &.mobile {
      display: none;
    }
  }
}

@keyframes ani_mainNav {
  0% {
    opacity: 0;
    transform: translateX(-50%);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateX(50%);
  }
}


.subnav, .sticky .subnavi{
  cursor: pointer;
  width: 275px;
  background: $color-2;
  border: none;
  padding: 0 25px;
  position: absolute;
  top: 150px;
  visibility: hidden;
  opacity: 0;
  z-index: 10;
  left: 50%;
  transform: translateX(-50%);
  transition: $t-medium;
  box-shadow: none;
  > li{
    display: block !important;
    margin: 0 !important;
    padding: 0 !important;
    a{
      color: #fff !important;
      display: block;
      font-size: 16px;
      padding: 12px !important;
      border-bottom: 1px solid rgba(255,255,255, 0.3);
      &:after{display: none !important;}
      &:hover, &:focus{
        background: $color-3;
        border-color: $color-3;
      }
    }
    &:last-child{
      a{
        border: none;
      }
    }
    &.active{
      a, a:hover{
      }
    }
    &.active{
      a, a:hover, a:active{
        background: #fff;
        color: $color-2 !important;
        border-color: #fff;
      }
    }
  }

  .subnav-open &{
    top: 49px;
    visibility: visible !important;
    opacity: 1 !important;
  }
}
/*==========  BREAKPOINTS  MAX-WIDTH ==========*/

@media only screen and (max-width: 1339px) {
  header {
    .container {
      width: 100% !important;
      margin: 0;
      padding: 0 25px;
      position: relative;
    }
    a {
      font-size: 15px;
    }
    nav.mainNav {
      li {
        margin-left: 12px;
        a {
          &:hover, &:focus {
            &:after {
            }
          }
        }
        &.active {
          a {
            &:after {
            }
          }
        }
      }
    }
    .reg .btn {
      margin-left: 20px;
      font-size: 15px;
    }
  }
}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/
@media only screen and (min-width: 1061px) {
  header {
  }

}

@media only screen and (max-width: 1060px) {

  header {
    .hamburger {
      display: block;
      position: absolute;
      z-index: 1001;
      right: 20px;
      top: 9px;
      width: 24px;
      height: 21px;
      text-align: center;
      svg {
        display: inline-block;
        + svg {
          display: none;
          transform: translate3D(2px, -2px, 0px);
        }
        .navOpen & {
          display: none;
          + svg {
            display: block;
          }
        }
      }
    }
    nav.mainNav {
      position: fixed;
      z-index: 1000;
      right: 0;
      top: 0;
      margin: 0;
      padding: 0;
      float: none;
      ul {
        margin: 0;
        padding: 105px 50px 50px 50px;
        position: absolute;
        z-index: 1;
        overflow-y: scroll;
        overflow-x: hidden;
        transition: $t-fast;
        right: 0;
        top: 0;
        min-width: 75vW;
        height: 100vH;
        background: rgba(var(--color-4), .92);
        transform: translateX(100%);
        li {
          display: block;
          text-align: center;
          margin: 0;
          border-bottom: 1px solid $color-2;
          a {
            font-size: 3vW;
            padding: 2.2vW 0;
            color: #fff !important;
            &:after {
              display: none;
            }
            &:hover, &:focus {
              color: $color-2 !important;
            }
          }
          &:first-child {
            border-top: 1px solid $color-2;
          }
          &.active a {
            color: $color-2 !important;
          }
        }
        .reg.mobile {
          display: block;
          float: none;
          border: none;
          a {
            &.btn.green {
              font-size: 2vW;
              margin: 0;
              padding: 1vW 3vW;
              display: inline-block;
            }
          }
        }
      }
      .navOpen & {
        ul {
          transform: translateX(0%);
        }
      }
    }
    .reg {
      margin-right: 50px;
      margin-top: 3px;
      a.login {
        //display: none;
      }
      a.btn.green {
        font-size: 12px;
      }
      &.mobile{
        display: block;
      }
    }
  }
  header nav.mainNav ul.subnav{
    position: static;
    opacity: 1;
    visibility: visible;
    width: 100%;
    transform: none;
    box-shadow: none;
    background: none;
    border:none;
    transform: none;
    height: auto;
    overflow: visible;
    margin: 0;
    padding: 0 0 2.2vW 0;
    &:before{
      display: none;
    }
    li{
      border:none;
      a{
        font-size: 2.5vW;
        color: #fff !important;
        opacity: 1;
        border: none;
        background: none;
        &:hover, &:focus{
          color: $color-2 !important;
          background: none;
        }
      }
      &.active{
        a, a:hover{
          color: $color-2 !important;
          opacity: 1;
        }
      }
    }
  }

  header.sticky ul:not(.subnav) li.active a {
    color: $color-2 !important;
  }
}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/

@media only screen and (max-width: 767px) {
  header {
    padding-top: 6px !important;
    .logo {
      width: 152px;
      transform: translateY(5px);
    }
    .hamburger {
      top: 16px;
    }
    .reg {
      transform: translateY(6.5px);
      a {
        font-size: 12px;
      }
    }
    nav.mainNav {
      ul {
        width: 100vW;
        li {
          a {
            font-size: 5vW;
            padding: 4vW 0;
          }
        }
        .reg.mobile {
          a {
            &.btn.green {
              font-size: 4vW;
              padding: 2vW 5vW;
            }
          }
        }
      }
      ul.subnav li a{
        font-size: 3.5vW;
      }
    }
  }
}

/*END BREAKPOINT MAX 767px-------------------------------------------------*/
@media only screen and (max-height: 600px) {
  header nav.mainNav ul {

  }
}
@media only screen and (max-width: 479px) {
  header {
    .logo {
      transform: translateY(3px);
    }
    .reg {
      display: none;
      &.mobile{
        display: block;
      }
    }
    nav.mainNav {
      ul {
        padding-left: 25px;
        padding-right: 25px;
        li {
          a {
            font-size: 24px;
            padding: 15px 0;
          }
        }
      }
      ul.subnav li a{
        font-size: 18px;
      }
    }
  }
}

/*END BREAKPOINT MAX 479px-------------------------------------------------*/


/*END BREAKPOINT MAX 374px-------------------------------------------------*/

@media only screen and (max-width: 320px) {

}

/*END BREAKPOINT MAX 320px-------------------------------------------------*/

/*==========  BREAKPOINTS  MIN-WIDTH ==========*/

@media only screen and (min-width: 320px) {

}

/*END BREAKPOINT MIN 320px-------------------------------------------------*/

@media only screen and (min-width: 375px) {

}

/*END BREAKPOINT MIN 375px-------------------------------------------------*/

@media only screen and (min-width: 480px) {

}

/*END BREAKPOINT MIN 480px-------------------------------------------------*/

@media only screen and (min-width: 768px) {

}

/*END BREAKPOINT MIN 768px-------------------------------------------------*/

@media only screen and (min-width: 1000px) {

}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/

@media only screen and (min-width: $screen-lg) {

}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/