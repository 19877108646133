/*TEXT ONLY*/
.textonly {
  h1, .h1 {
    margin-bottom: 36px;
  }

  h2, .h2 {
    line-height: 1.4;
  }

  h2, h3, h4 {
    margin-bottom: 24px;
  }


 p, ul li, ol li, a, p a, li a {
    color: $grey;
  }

  p {
    margin-bottom: 24px;
    + *:not(p) {
      padding-top: 0;
    }
  }

  ol, ul {
    margin-bottom: 24px;
    padding-top: 0;
  }

  ul.checked {
    margin: 0 0 36px 36px;
    @media screen and (max-width: 768px){
      margin: 0 0 36px 22px;
    }
    li {
      margin-bottom: .5em;
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
}

ul + p, ol + p {
  padding-top: 0;
}

header + .textonly {
  margin-top: 100px;
}


@media only screen and (max-width: 767px) {
  header + .textonly {
    margin-top: 33px;
  }
}
