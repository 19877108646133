@font-face {
    font-family: 'P4B';
    font-style: normal;
    font-weight: 400;
    src: local(''),
        url('../p4b/webfont/p4b-400.woff2') format('woff2'),
        url('../p4b/webfont/p4b-400.woff') format('woff'); 
}

@font-face {
    font-family: 'P4B';
    font-style: italic;
    font-weight: 400;
    src: local(''),
        url('../p4b/webfont/p4b-400italic.woff2') format('woff2'),
        url('../p4b/webfont/p4b-400italic.woff') format('woff'); 
}

@font-face {
    font-family: 'P4B';
    font-style: normal;
    font-weight: 700;
    src: local(''),
        url('../p4b/webfont/p4b-700.woff2') format('woff2'),
        url('../p4b/webfont/p4b-700.woff') format('woff'); 
}

@font-face {
    font-family: 'P4B';
    font-style: italic;
    font-weight: 700;
    src: local(''),
        url('../p4b/webfont/p4b-700italic.woff2') format('woff2'),
        url('../p4b/webfont/p4b-700italic.woff') format('woff');
}

@font-face {
    font-family: 'P4B';
    font-style: normal;
    font-weight: 500;
    src: local(''),
        url('../p4b/webfont/p4b-500narrow.woff2') format('woff2'),
        url('../p4b/webfont/p4b-500narrow.woff') format('woff');
}

@font-face {
    font-family: 'P4B';
    font-style: normal;
    font-weight: 800;
    src: local(''),
        url('../p4b/webfont/p4b-800narrow.woff2') format('woff2'),
        url('../p4b/webfont/p4b-800narrow.woff') format('woff');
}