.press-social {
  padding-top: 0;
  h2 {
    color: $grey;
    font-size: 24px;
    line-height: 33px;
    letter-spacing: -0.27px;
    margin-bottom: 20px;
    text-align: left;
  }
  .uh-pressekontakt {
    background: #fff;
    padding: 50px;
    figure {
      display: inline-block;
      max-width: 244px;
      border-radius: 50%;
      margin-bottom: 15px;
      overflow:hidden;
    }
    .info {
      text-align: left;
      font-size: 18px;
      line-height: 24px;
      color: $grey;
      letter-spacing: 0;
      a {
        display: block;
        color: $grey;
        font-weight: 400;
        text-decoration: none;
        transition: $t-fast;
        &:hover, &:focus {
          color: $color-2;
        }
      }
    }
  }
  .uh-social {
    padding: 50px;
    a{
      transition: $t-fast;
      display: inline-block;
      width: calc(50% - 4px);
      max-width: 130px;
      margin: 15px;
      &:hover, &:focus{
        transform: scale(1.15);
      }
    }
  }
}

/*==========  BREAKPOINTS  MAX-WIDTH ==========*/

@media only screen and (max-width: 1339px) {

}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/

@media only screen and (max-width: 999px) {
  .press-social{
    .uh-social {
      padding: 20px;
      height: auto !important;
    }
    .uh-pressekontakt{
      margin-bottom: 51px;
    }
  }
}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/

@media only screen and (max-width: 767px) {
  .press-social{
    .uh-pressekontakt{
      height:auto !important;
    }
    .uh-social a {
      width: calc(50% - 70px);
      max-width: none;
      margin: 25px;
    }
  }
}

/*END BREAKPOINT MAX 767px-------------------------------------------------*/

@media only screen and (max-width: 480px) {
  .press-social .uh-social a {
    width: calc(50% - 40px);
    max-width: none;
    margin: 15px;
  }
}

/*END BREAKPOINT MAX 479px-------------------------------------------------*/
.pressekontakt {

}

.uh-social {

}

@media only screen and (max-width: 375px) {
  .press-social .uh-pressekontakt {
    padding: 25px;
  }
}

/*END BREAKPOINT MAX 374px-------------------------------------------------*/

@media only screen and (max-width: 320px) {

}

/*END BREAKPOINT MAX 320px-------------------------------------------------*/

/*==========  BREAKPOINTS  MIN-WIDTH ==========*/

@media only screen and (min-width: 320px) {

}

/*END BREAKPOINT MIN 320px-------------------------------------------------*/

@media only screen and (min-width: 375px) {

}

/*END BREAKPOINT MIN 375px-------------------------------------------------*/

@media only screen and (min-width: 480px) {

}

/*END BREAKPOINT MIN 480px-------------------------------------------------*/

@media only screen and (min-width: 768px) {

}

/*END BREAKPOINT MIN 768px-------------------------------------------------*/

@media only screen and (min-width: 1000px) {

}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/

@media only screen and (min-width: $screen-lg) {

}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/