
.slick-slide, .slick-slide:focus{
  outline:none !important;

}
.slick-dots {
  position: absolute;
  z-index: 999;
  width: 100%;
  text-align: center;
  bottom:0;
  margin: 0;
  padding: 0;
  li {
    width: 15px;
    height: 15px;
    background: #fff;
    border-radius:50%;
    transition: $t-fast;
    display: inline-block;
    margin: 0 5px;
    cursor: pointer;
    &:hover{
      background: $color-4;
    }
    &.slick-active{
      background: $color-2 !important;
    }
    button {
      display: none;
    }
  }
}
.progress {
  display: block;
  width: 100%;
  height: 10px;
  overflow: hidden;
  background: $color-2;
}


@media only screen and (max-width: 374px) {
  .slick-dots li {
    width: 12px;
    height: 12px;
    margin: 0 4px;
  }
}
