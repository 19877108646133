.testimonial3er {
  .item {
    text-align: center;
    figure {
      display: inline-block;
      width: 100%;
      max-width: 190px;
      margin-bottom: 15px;
      overflow: hidden;
      border-radius: 100px;
    }
    .name{
      text-align: left;
      font-weight: 600;
      font-size: 24px;
      line-height: 33px;
      color: #FFFFFF;
      letter-spacing: -0.34px;
      margin-bottom: 10px;
    }
    blockquote {
      text-align: left;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      color: #FFFFFF;
      letter-spacing: -0.26px;
    }
  }
}



/*==========  BREAKPOINTS  MAX-WIDTH ==========*/


@media only screen and (max-width: 1339px) {

}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/


@media only screen and (max-width: 999px) {
  .testimonial3er {
    .item {
      figure {
      }
      .name{
        font-size: 18px;
        line-height: 24px;
      }
      blockquote {
        font-size: 14px;
        letter-spacing: -0.2px;
        line-height: 19px;
      }
    }
  }
}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/


@media only screen and (max-width: 767px) {
  .testimonial3er{
    [class*="col-"]{
      .item{
        margin-bottom: 50px;
        text-align: center;
        .name, blockquote{
          text-align: center;
        }
        blockquote {
          font-size: 18px;
          line-height: 1.4;
        }
      }
      &:last-child {
        .item {
          margin-bottom: 0;
        }
      }
    }
  }
}

/*END BREAKPOINT MAX 767px-------------------------------------------------*/


@media only screen and (max-width: 479px) {

}

/*END BREAKPOINT MAX 479px-------------------------------------------------*/


@media only screen and (max-width: 374px) {

}

/*END BREAKPOINT MAX 374px-------------------------------------------------*/


@media only screen and (max-width: 320px) {

}

/*END BREAKPOINT MAX 320px-------------------------------------------------*/

