/*Claimbox*/
section.claimbox{
padding-bottom: 0;
  .info{
    display: table;
    > span{
      display: table-cell;
      vertical-align: middle;
      transform: translateY(-25px);
    }
  }
}
/*==========  BREAKPOINTS  MAX-WIDTH ==========*/


@media only screen and (max-width: 1339px) {

}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/


@media only screen and (max-width: 999px) {

}

/*END BREAKPOINT MAX 991px-------------------------------------------------*/


@media only screen and (max-width: 767px) {

}

/*END BREAKPOINT MAX 767px-------------------------------------------------*/


@media only screen and (max-width: 580px) {
  section.claimbox{
    padding-bottom: 51px;
    .claim.hero{
      img{
        position: absolute;
        bottom: -51px;
        left: -41px;
        height: 180%;
        width: auto;
      }
    }
    .info{
      > span{
        transform: translateY(0px);
      }
    }
  }
}

/*END BREAKPOINT MAX 479px-------------------------------------------------*/
@media only screen and (max-width: 425px) {
  section.claimbox{
    .claim.hero{
      img{
        height: 155%;
      }
    }
  }
}

@media only screen and (max-width: 375px) {
  section.claimbox{
    .claim.hero{
      img{
        height: 120%;
      }
    }
  }
}

/*END BREAKPOINT MAX 374px-------------------------------------------------*/


@media only screen and (max-width: 360px) {
  section.claimbox{
    .claim.hero{
      img{
        height: 110%;
      }
    }
  }
}

/*END BREAKPOINT MAX 320px-------------------------------------------------*/



/*==========  BREAKPOINTS  MIN-WIDTH ==========*/


@media only screen and (min-width: 320px) {

}

/*END BREAKPOINT MIN 320px-------------------------------------------------*/


@media only screen and (min-width: 375px) {

}

/*END BREAKPOINT MIN 375px-------------------------------------------------*/


@media only screen and (min-width: 480px) {

}

/*END BREAKPOINT MIN 480px-------------------------------------------------*/

@media only screen and (min-width: 768px) {

}

/*END BREAKPOINT MIN 768px-------------------------------------------------*/


@media only screen and (min-width: 1000px) {

}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/

@media only screen and (min-width: $screen-lg) {
  section.claimbox{
    .claim.info{
      img{
        height: 110%;
      }
    }
  }
}

/*END BREAKPOINT MIN 992px-------------------------------------------------*/