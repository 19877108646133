/*REGISTER-Login*/

section.hero.register {
  height: 100vH;
  background: url(/typo3conf/ext/unternehmerheld/Resources/Public/Img/hero/header-photo.jpg) no-repeat center top;
  background-size: cover;

  .overlay {
    position: relative;
    bottom: auto;
    left: auto;
    width: 100%;
    height: 100%;
    background: transparentize(#43484A, .3);
    padding: 0;
    .container {
      padding-top: 150px;
    }
    h1, .h1 {
      font-size: 24px;
      color: #FFFFFF;
      letter-spacing: 0;
      line-height: 33px;
      font-weight: 600;
      margin: 0;
      display: block;
      text-align: left;
    }
    form {
      text-align: left;
      font-size: $fontsize-base;
      h2 {
        font-size: $fontsize-base;
        font-weight: 400;
        color: #FFFFFF;
        letter-spacing: -0.31px;
        margin: 30px 0 10px 0;
      }
      .disclamer {
        margin: 25px 0;
        label {
          font-size: 14px;
          color: #FFFFFF;
          letter-spacing: -0.24px;
          font-weight: 600;
          line-height: 19px;
          input[type="radio"] {
            margin-top: 2px;
          }
          a {
            transition: $t-fast;
            font-weight: bold;
            text-decoration: none;
            &:hover, &:focus {
              color: $color-2;
            }
          }
        }
      }
      .smallText{
        font-size: 14px;
        line-height: 19px;
        color: #FFFFFF;
        letter-spacing: -0.24px;
        font-weight: 500;
        a {
          transition:$t-fast;
          font-weight: 600;
          text-decoration: none;
          &:hover, &:focus{
            color: $color-2;
          }
        }
      }
      .btn {
        margin-top: 0;
      }
    }
  }
  &.login{
    h2{
      font-size: $fontsize-base;
      color: #FFFFFF;
      letter-spacing: -0.31px;
      line-height: 24px;
    }
    .btn{
      margin-bottom: 10px;
    }
  }
}



@media only screen and (min-height: 950px) {
  section.hero.register {
    height: 100vH;
    background: url(/typo3conf/ext/unternehmerheld/Resources/Public/Img/hero/header-photo.jpg) no-repeat center top;
    background-size: cover;

    .overlay {
      display: table;
      .container {
        display: table-cell;
        vertical-align: middle;
        padding-top: 0;
      }
    }
  }
}

@media only screen and (max-width: 767px) and (max-height: 949px) {
  section.hero.register .overlay .container {
    padding-top: 90px;
  }
}
